<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                   {{$t('TIPOAGENDAMENTOCADASTRO.SALVANDOAGENDAMENTO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-half">
                                    <ValidationProvider rules="required" name="model.nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOAGENDAMENTOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column column is-one-quarter">
                                    <ValidationProvider name="model.cor" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOAGENDAMENTOCADASTRO.COR')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input type="text" v-model="model.cor"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-quarter">
                                    <br>
                                    <input type="color" :change="corSelecionada != null ? model.cor = corSelecionada.substring(1) : null" v-model="corSelecionada">
                                </div>
                            </div>

                            <search-id-name :id.sync="model.unidadeId" :item.sync="model.unidade" :label="$t('TIPOAGENDAMENTOCADASTRO.UNIDADE')" table="Unidade"></search-id-name>
                            
                            <search-id-name :id.sync="model.equipmentId" :item.sync="model.equipmet" label="Equipamento" table="EquipmentDicom"></search-id-name>
                            
                        </article>

                        <article class="panel">
                            <div class="panel-heading has-text-centered">
                                {{$t('TIPOAGENDAMENTOCADASTRO.ESCALA')}}
                            </div>
                            <div class="panel-block">
                                <table class="table is-bordered is-striped is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.IDESCALA')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.DIADASEMANA')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.NOMEESCALA')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.HORAINICIO')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.HORAFIM')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.INTERVALO')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in model.escalas.filter(i => i.show===true)" v-bind:key="index">
                                            <td><b-button
                                                type="is-danger"
                                                native-type="button"
                                                icon-left="delete"
                                                class="button-delete"
                                                @click ="deleteItem(item,index)" >
                                            </b-button>                                            
                                            </td>
                                            <td>{{item.id}}</td>
                                            <td> 
                                                <b-select class="is-fullwidth" v-model="item.diaDaSemana">
                                                    <option value="1" >Segunda</option>
                                                    <option value="2" >Terça</option>
                                                    <option value="3" >Quarta</option>
                                                    <option value="4" >Quinta</option>
                                                    <option value="5" >Sexta</option>
                                                    <option value="6" >Sábado</option>
                                                    <option value="0" >Domingo</option>
                                                </b-select>                                            
                                            
                                            </td>
                                            <td> <input class="input" type="text" v-model="item.nome"> </td>
                                            <td> <input class="input" type="text" v-mask="'##:##'" v-model="item.horaInicioEscala"> </td>
                                            <td> <input class="input" type="text" v-mask="'##:##'" v-model="item.horaFimEscala"> </td>
                                            <td> <input class="input" type="text" v-mask="'##'" v-model="item.intervaloDuracaoAtendimento"> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>     
                                <div class="panel-heading has-text-centered">
                                    <b-button
                                        type="is-success"
                                        native-type="button"
                                        icon-left="plus"
                                        class="center"
                                        @click="addItem()">
                                        {{$t('TIPOAGENDAMENTOCADASTRO.INCLUIR')}}
                                    </b-button>  
                                </div>                                                     
                        </article>                     

                    </div>                    
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-show="isInRole('tipos-agendamento-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('TIPOAGENDAMENTOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('TIPOAGENDAMENTOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        components: {
            titlebar,
            searchIdName
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    cor: null,
                    unidadeId: null,
                    equipmentId: null,
                    escalas: []

                },
            
                corSelecionada: null,
                salvandoDados: false,
                retornarTipoAgendamento: Boolean(this.$route.query.retornarTipoAgendamento) ?? false,
            }
        },
        created() {
            if(this.$route.params.id){
                this.$http.get('/api/manutencao/tipoagendamento?id=' + this.$route.params.id)
                    .then(res => {                        
                        this.model = res.data;

                        this.model.escalas.forEach((escala) => {
                            escala.show = true;
                        });
                        
                        this.corSelecionada = "#" + this.model.cor;
                        
                        
                    });
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('TIPOAGENDAMENTOCADASTRO.MANUTENCAO'),
                    this.$t('TIPOAGENDAMENTOCADASTRO.TIPOAGENDAMENTO'),
                    this.model.id ?? 'novo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            addItem() {
                this.model.escalas.push({
                    id: 0,
                    nome: null,
                    horaInicioEscala: null,
                    horaFimEscala: null,
                    intervaloDuracaoAtendimento: null,
                    show:true
                })
            },   
            deleteItem(item,index) {
                if(item.id==0){
                    this.model.escalas.splice(index, 1);
                }else{
                    item.show = false; 
                }
                
                
                this.$forceUpdate();                
            },                     
            onSubmit() {
                this.salvandoDados = true;
                let todosEscalasPreenchidas = true;


                if(this.model.id == null){
                    this.model.id = 0
                }

                this.model.escalas.filter(i => i.show===true).forEach((escala) => {
                                                     
                    if(!escala.nome || !escala.intervaloDuracaoAtendimento
                    || !escala.horaInicioEscala || !escala.horaFimEscala  ){
                        todosEscalasPreenchidas= false;
                     }
                });

                if(todosEscalasPreenchidas){
                        
                        this.$http.post(`/api/manutencao/tipoagendamento`, this.model)
                                .then((res) => {
                                    this.salvandoDados = false;

                                    if(this.retornarTipoAgendamento){
                                        localStorage.retornarTipoAgendamento = res.data.id;   
                                        window.close();
                                    } else{
                                        this.$router.back();
                                    }

                                
                                })
                                .catch((error) => {
                                    this.salvandoDados = false;
                                    throw error
                                });                      

                }else{
                
                        this.$buefy.dialog.alert({
                            title: this.$t('TIPOAGENDAMENTOCADASTRO.ATENCAO'),
                            message: this.$t('TIPOAGENDAMENTOCADASTRO.CAMPOSOBRIGATORIOS'),
                            type: 'is-warning',
                            hasIcon: true                                          
                        });                    
                        this.salvandoDados = false;
                        return null;
                }
                
 

            }
        }
    }
</script>