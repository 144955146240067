<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <article class="level">
                <div class="level-left">
                    <div class="is-light">
                        <label><strong>{{$t('ENTREGARESULTADOS.CODIGOGUIA')}}</strong></label>
                        <div class="field has-addons">
                            <div class="control">
                                <input type="text" v-model="codigoDaGuia" ref="codigoDaGuia" class="input"/>
                            </div>
                            <div class="control">
                                <button v-if="loading" class="is-primary button is-loading">{{$t('ENTREGARESULTADOS.LOADING')}}</button>
                                <a v-else class="button is-primary" @click="incluirGuia();">
                                {{$t('ENTREGARESULTADOS.INCLUIR')}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
        
        <div class="panel panel-primary">
            <b-table :data="guias">                               
                <template slot-scope="props">

                    <b-table-column field="id" :label="$t('ENTREGARESULTADOS.GUIA')">
                        {{ props.row.id }}
                    </b-table-column>

                    <b-table-column field="dataEmissao" :label="$t('ENTREGARESULTADOS.DATAEMISSAO')">
                        {{ props.row.dataDeEmissao }}
                    </b-table-column>

                    <b-table-column field="pacienteNome" :label="$t('ENTREGARESULTADOS.PACIENTE')">
                        {{ props.row.pacienteNome }}
                    </b-table-column>

                    <b-table-column field="convenioNome" :label="$t('ENTREGARESULTADOS.CONVENIO')">
                        {{ props.row.convenioNome }}
                    </b-table-column>

                    <b-table-column field="exames" :label="$t('ENTREGARESULTADOS.EXAMES')">
                        <span v-for="(exame, index) in props.row.exames" v-bind:key="index">{{exame}} </span>
                    </b-table-column>

                    <b-table-column field="remove" label="">
                        <b-button @click="removeLinha(props.row.id)" type="is-danger">
                            <b-icon
                                icon="delete"
                                size="is-small">
                            </b-icon>
                        </b-button>
                    </b-table-column>
                </template>
            </b-table>
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                :loading="loading"
                                native-type="button"
                                icon-left="check-circle"
                                @click="entregar();">
                       {{$t('ENTREGARESULTADOS.ENTREGAR')}}
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        {{$t('ENTREGARESULTADOS.CANCELAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>
<script>
    import titlebar from '@/components/titlebar.vue'
    import moment from 'moment';

    export default {
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('ENTREGARESULTADOS.ATENDIMENTO'),
                    this.$t('ENTREGARESULTADOS.ENTREGARESULTADOS')
                ]
            }
        },
        data() {
            return {
                codigoDaGuia: null,
                guias: [],
                loading: false
            }
        },
        methods: {
            incluirGuia(){
                this.loading = true;
                this.$http.get('/api/atendimento/BuscarGuia?id=' + this.codigoDaGuia)
                    .then(res => res.data)
                    .then(data => {     

                        data.dataDeEmissao = moment(data.dataDeEmissao).format('DD/MM/YYYY HH:mm:ss');
                        this.guias.push(data);
                        this.loading = false;
                    });

                this.codigoDaGuia = "";
            },
            removeLinha(guiaId){
                this.guias = this.guias.filter(item => item.id != guiaId);
            },
            entregar(){
                this.$buefy.dialog.prompt({
                    message: this.$t('ENTREGARESULTADOS.MENSAGEMDESTINO'),
                    inputAttrs: {
                        type: 'text',
                        placeholder: '',
                        value: 0,
                        title: this.$t('ENTREGARESULTADOS.TITULODESTINO')
                    },
                    trapFocus: true,
                    confirmText: this.$t('ENTREGARESULTADOS.CONFIRMAR'),
                    cancelText: this.$t('ENTREGARESULTADOS.CANCELAR'),
                    onConfirm: (value) => {
                        const entregarresultados = {
                            guias: this.guias.map(item => item.id), 
                            dados: value
                        };

                        this.loading = true;
                        this.$http.post(`/api/atendimento/entregarresultados`, entregarresultados )
                            .then(({ data }) => {
                                this.guias = [];
                                this.loading = false;
                            }); 

                    }
                })  

            }
        }
    }
</script>