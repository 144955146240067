<template>
  <div class="modal-card" style="width: 100%;">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ titulo }}</p>
    </header>
    <b-message
      v-for="(erro, index) in erros"
      v-bind:key="index"
      title="Erro"
      type="is-danger"
      aria-close-label="Fechar mensagem"
    >
      {{ erro }}
    </b-message>
    <section class="modal-card-body list is-paddingless is-marginless">
      <div class="panel-block">
        <b-table :data="interno.usuarios" striped hoverable>
          <template slot-scope="props" @click="abrirCadastro(props.row.id)">
            <b-table-column field="id" label="Id">
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="email" label="Email" >
              <b-field>
                <b-input
                  v-model="props.row.email"
                  maxlength="100"
                  ref="email"
                  :has-counter="false"
                ></b-input>
              </b-field>
            </b-table-column>
            <b-table-column field="acessaPrecos" label="Acessa Preços?">
              <b-checkbox  v-model="props.row.acessaPrecos" @input="changed(props.row)"></b-checkbox >
            </b-table-column>
            <b-table-column field="recebeEmail" label="Recebe e-mail?">
              <b-checkbox  v-model="props.row.recebeEmail" @input="changed(props.row)"></b-checkbox >
            </b-table-column>
            <b-table-column field="acessaResultado" label="Acessa Resultado?">
              <b-checkbox  v-model="props.row.acessaResultado" @input="changed(props.row)"></b-checkbox >
            </b-table-column>
            <b-table-column field="incluirGuia" label="Incluir guias?">
              <b-checkbox  v-model="props.row.incluirGuia" @input="changed(props.row)"></b-checkbox >
            </b-table-column>
            <b-table-column field="desativado" label="Desativado">
              <b-checkbox 
                v-model="props.row.desativado"
                v-if="props.row.id"
                @input="changed(props.row)"                
              ></b-checkbox >
            </b-table-column>
            <b-table-column
              field="reenviarConfirmacao"
              label="Reenviar Confirmação"
            >
              <b-checkbox 
                v-model="props.row.reenviarConfirmacao"
                v-if="props.row.id"
                @input="changed(props.row)"                                        
              >
              </b-checkbox>
            </b-table-column>
            <b-table-column field="acoes" label="Ações">
              <b-button
                type="is-success"
                v-if="!props.row.id"
                @click="criarUsuario(props.row)"
                >Criar</b-button
              >
              <b-button type="is-success" v-if="props.row.id && props.row.changed" @click="atualizarUsuario(props.row)"
                >Atualizar</b-button
              >
            <b-button
              title="Cancelar usuário" 
              type="is-danger" 
              v-if="props.row.id && !props.row.changed"  
              @click="desativarUsuario(props.row.id)"
            >
              X
              </b-button>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-pulled-right"
        type="button"
        @click="sair()"
      >
        Fechar
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    usuarios: null,
    titulo: null,
    success: null,
    idConvenio:null
  },
  computed: {},
  data(){
      return {
        interno : {
          usuarios: []
        },
        erros: [],
        desativaUsuarioSecundario: true,
      }
  },
  mounted(){
      this.interno.usuarios = this.usuarios;
      setTimeout(() => {
        this.$refs.email.focus();      
      },200)
  },
  methods: {
    changed(row){
      row.changed = true;
      row.temErros = false;
    },
    validate(data){
      this.changed(data);
      return true;
    },
    async criarUsuario(data){
      if (this.validate(data)){
        try {
        const resposta = await this.$http.post(`/api/manutencao/criaUsuarioSecundario?convenioId=${this.idConvenio}&acessaprecos=${data.acessaPrecos}&recebeemail=${data.recebeEmail}&email=${data.email}&incluirGuia=${data.incluirGuia}&acessaResultado=${data.acessaResultado}`)
              .catch(err => {
                  this.erros.push(err.body);
                  this.$refs.email.focus();
                  throw err;
                });
          this.erros = [];
          data.temErros = null;
          this.$parent.close();
        }finally{
          this.$forceUpdate();
        }
      }

    },
    async atualizarUsuario(data){
      if (this.validate(data)){
        try {
          const resposta = await this.$http.post(`/api/manutencao/atualizaUsuarioSecundario?usuarioConvenioId=${data.usuarioConvenioId}&acessaPrecos=${data.acessaPrecos}&recebeEmail=${data.recebeEmail}&desativado=${data.desativado}&reenviarConfirmacao=${data.reenviarConfirmacao}&convenioId=${this.idConvenio}&incluirGuia=${data.incluirGuia}&acessaResultado=${data.acessaResultado}`)
            .catch(err => {
              this.erros.push(err.body);
              this.$refs.email.focus();
              throw err;
            });
            this.erros = [];
            data.temErros = null;
            this.$parent.close();
        }finally{
          this.$forceUpdate();
        }
      }
    },
    desativarUsuario(data){
        this.$http.get(`/api/manutencao/deletarUsuarioSecundarioConvenio?convenioId=${this.idConvenio}&desativarSecundarios=${this.desativaUsuarioSecundario = true}&usuarioSecundarioConvenioId=${data}`)
          .then(() => {
              this.$router.back()
          })
          .catch(err => {
              console.error(err)
              Object.keys(err.body.errors).forEach(x => {
                  this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
              })
              this.$buefy.toast.open({
                  duration: 5000,
                  message: 'Erro ao excluir usuario: ' + err.body.title,
                  type: 'is-danger',
                  queue: false
              })
          })
    },   
    sair() {
        this.$emit('update:usuarios', this.interno.usuarios);
        this.$emit('update:success', true);
        this.$parent.close();
    },
  },
};
</script>
