import { render, staticRenderFns } from "./contaspagar.vue?vue&type=template&id=554fe5c0&"
import script from "./contaspagar.vue?vue&type=script&lang=js&"
export * from "./contaspagar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports