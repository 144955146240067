<template>
    <section>
        <section class="hero is-primary is-navbar-main navbar-default">
            <div class="hero-body is-paddingless" style="padding:20px!important; max-height:150px;">
                <img v-if="config.logoBase64" :src="'data:image/png;base64, ' + config.logoBase64" height="30" class="is-pulled-right" />
                <p class="title is-spaced">
                    Senha de atendimento
                </p>
                <p class="subtitle">
                    {{ config.nomeDoLaboratorio }}
                </p>
                <b-taglist attached>
                    <b-tag size="is-medium">{{ currentDate }}</b-tag>
                    <b-tag type="is-dark" size="is-medium">{{ currentTime }}</b-tag>
                    <b-tag size="is-medium">{{ nomeUnidade }}</b-tag>
                </b-taglist>
            </div>
        </section>
        <table class="table is-fullwidth" style="background-color: transparent!important;">
            <tbody>
                <tr>
                    <td width="70%" :style="senhaTdStyle">
                        <b-taglist attached v-if="senhaAtiva" style="justify-content:center;">
                            <b-tag :type="senhaAtiva.buttonType" size="is-large">{{ senhaAtiva.tipo }}</b-tag>
                            <b-tag type="is-dark is-size-1 blink">{{ senhaAtiva.sequencia }}</b-tag>
                            <b-tag type="is-warning" size="is-large">GUICHÊ <b>{{ senhaAtiva.guiche }}</b></b-tag>
                            <b-tag v-if="senhaAtiva.situacao == 'Coleta'" type="is-info" size="is-small is-large">{{ senhaAtiva.situacao }}</b-tag>
                            <b-tag v-if="senhaAtiva.situacao == 'Recepcao'" type="is-light" size="is-small is-large">{{ senhaAtiva.situacao }}</b-tag>
                        </b-taglist>
                    </td>
                    <td v-if="config.videoSenhaDeAtendimento" class="has-text-right">
                        <iframe width="560" style="height:315px!important" :src="config.videoSenhaDeAtendimento" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="container is-fluid">
            <p class="subtitle" v-if="ultimasUnidade.length > 0">Últimas chamadas</p>
            <div class="columns is-multiline" style="zoom:150%">
                <div class="column is-4 is-4-mobile" v-for="item, index in ultimasUnidade" :key="index">
                    <b-taglist attached>
                        <b-tag :type="item.buttonType" size="is-small">{{ item.tipo }}</b-tag>
                        <b-tag type="is-dark" size="is-medium">{{ item.sequencia }}</b-tag>
                        <b-tag type="is-warning" size="is-small">GUICHÊ <b>{{ item.guiche }}</b></b-tag>
                        <b-tag v-if="item.situacao == 'Coleta'" type="is-info" size="is-small">{{ item.situacao }}</b-tag>
                        <b-tag v-if="item.situacao == 'Recepcao'" type="is-light" size="is-small">{{ item.situacao }}</b-tag>
                    </b-taglist>
                </div>
            </div>
        </div>
    </section>
</template>

<!--<style type="text/css">
    html {
        overflow: hidden!important;
    }
</style>-->

<script>
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                currentTime: null,
                currentDate: null,
                timeInterval: null,
                senhaAtiva: null
            }
        },
        computed: {
            ...mapState([
                'config',
                'unidades'
            ]),
            ...mapState('senhaAtendimento', [
                'chamando',
                'ultimas'
            ]),
            ultimasUnidade() {
                return this.ultimas.filter(x => x.unidadeId == this.$route.params.id)
            },
            nomeUnidade() {
                return this.unidades != undefined
                    ? this.unidades.find(x => x.id == parseInt(this.$route.params.id))?.nome
                    : ''
            },
            senhaTdStyle() {
                if (this.config.videoSenhaDeAtendimento)
                    return "zoom:200%;"
                else
                    return "zoom:250%;"
            }
        },
        watch: {
            chamando(novaSenha) {
                if (novaSenha != undefined && novaSenha.unidadeId == this.$route.params.id) {
                    this.senhaAtiva = novaSenha
                    this.playBeep()
                }
            }
        },
        methods: {
            updateCurrentTime() {
                this.$moment.locale('pt-BR')
                this.currentTime = this.$moment().format('HH:mm')
                this.currentDate = this.$moment().format('DD/MM/YYYY')
            },
            playBeep() {
                const audio = new Audio('http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3')
                audio.play()
            }
        },
        mounted() {
            this.updateCurrentTime()
            this.timeInterval = setInterval(() => this.updateCurrentTime(), 1 * 10000)
        },
        beforeDestroy: function () {
            clearInterval(this.timeInterval)
        }
    }

</script>