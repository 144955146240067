<template>
    <div>
        <div id="otimizar_imagem" style="display: none;"></div>
        <div class="panel" v-for="(item, index) in respostasPesquisas" :key="index">
            <div class="panel-heading">
                <div class="columns">
                    <div class="column is-9">
                        <b-tag type="is-dark">{{ item.pesquisa.id }}</b-tag> {{ item.pesquisa.nome }}
                    </div>
                    <div class="column is-3">
                        <b-tag type="is-info" v-for="(guiaExameId, j) in item.guiaExameIds" :key="j">
                            {{ exames.filter(exame => exame.item == guiaExameId.item)[0].exameApelido }}
                        </b-tag>
                    </div>
                </div>
            </div>
            <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth" v-for="(pergunta, idxperg) in item.pesquisa.perguntas" :key="idxperg">
                <tr v-for="(perguntaItem, idxpergitem) in pergunta.perguntaItens" :key="idxpergitem">
                    <th width="40%">
                        <span v-if="idxpergitem == 0">{{ pergunta.texto }}</span>
                    </th>
                    <td v-if="perguntaItem.aceitaDescritivo">
                        <b-input v-if="item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0]"
                            type="text" v-model="item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].descritivo"></b-input>
                        <b-input v-else
                            type="text" v-model="perguntaItem.descritivo"></b-input>
                            
                    </td>
                    <td v-else-if="perguntaItem.aceitaData">
                        <b-datepicker editable v-model="item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].descritivoData"></b-datepicker>
                    </td>
                    <td v-else-if="perguntaItem.aceitaArquivo">

                        <div class="field" >
                            <label class="label" style="display: contents;"> {{$t('MODALRESPOSTAPESQUISA.NOMEARQUIVO')}}:</label>  {{ retornaNomeArquivo(item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0]) }}
                        </div>

                        <img style="display:block;" width="100%" v-if="item.respostas.length > 0 && !config.naoCarregarArquivosRespostaPesquisa &&
                        item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].extensao == 'png'"
                        :src="'data:image/png;base64,' + 
                        item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].arquivoBase64"/>
                        <img style="display:block;" width="100%" v-else-if="item.respostas.length > 0 && !config.naoCarregarArquivosRespostaPesquisa &&
                        item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].extensao == 'jpg'"
                        :src="'data:image/jpg;base64,' + 
                        item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].arquivoBase64"/>
                        <img style="display:block;" width="100%" v-else-if="item.respostas.length > 0 && !config.naoCarregarArquivosRespostaPesquisa &&
                        item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].extensao == 'jpeg'"
                        :src="'data:image/jpeg;base64,' + 
                        item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].arquivoBase64"/>
                        <img style="display:block;" width="100%" v-else-if="item.respostas.length > 0 && !config.naoCarregarArquivosRespostaPesquisa &&
                        item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].extensao == 'gif'"
                        :src="'data:image/gif;base64,' + 
                        item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].arquivoBase64"/>
                        <pre v-else-if="item.respostas.length > 0 &&  !config.naoCarregarArquivosRespostaPesquisa &&
                            (item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].extensao == 'txt' || 
                            item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].extensao == 'xml' || 
                            item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].extensao == 'json')">
                            {{item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].arquivoBase64}}
                        </pre>
                        <div v-else-if="item.respostas.length > 0 && !config.naoCarregarArquivosRespostaPesquisa &&
                            item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].extensao != null">
                            <pre :id="'arquivo_' + pergunta.id" style="display:none;">
                                {{item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].arquivoBase64}}
                            </pre>
                            <div class="columns">
                                <b-field class="file" style="margin: 0 auto; margin-top: 10px;">
                                    <a class="button is-primary is-fullwidth" @click="downloadFile(pergunta.id, item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].extensao,
                                        item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].arquivoBase64)" 
                                        :title="$t('MODALRESPOSTAPESQUISA.CLIQUEDOWNLOAD')"><b-icon icon="cloud-download-outline"></b-icon> <span> {{$t('MODALRESPOSTAPESQUISA.BAIXARARQUIVO')}} </span></a>                         
                                </b-field>
                            </div>                                  
                        </div>
                        <div v-else-if="config.naoCarregarArquivosRespostaPesquisa">
                            <div class="columns">
                                <b-field class="file" style="margin: 0 auto; margin-top: 10px;">
                                    <a class="button is-primary is-fullwidth" @click="downloadFileBuscar(pergunta.id, item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].extensao,
                                        item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].descritivo,
                                        item, pergunta.id, perguntaItem.id, index)" 
                                        :title="$t('MODALRESPOSTAPESQUISA.CLIQUEDOWNLOAD')"><b-icon icon="cloud-download-outline"></b-icon> <span> {{$t('MODALRESPOSTAPESQUISA.BAIXARARQUIVO')}} </span></a> 
                                </b-field>
                            </div>

                        </div>
                        <div class="columns">                            
                            <b-field class="file" style="margin: 0 auto; margin-top: 10px;">
                                <b-upload @input="incluirAnexo" v-model="arquivoInserido[pergunta.id]" expanded>
                                    <a class="button is-primary is-fullwidth">
                                    <b-icon icon="upload"></b-icon>
                                    <span>{{ (arquivoInserido[pergunta.id]) ? arquivoInserido[pergunta.id].name : $t('MODALRESPOSTAPESQUISA.ALTERARARQUIVO') }}</span>
                                    </a>
                                </b-upload>
                            </b-field>

                        </div>
                    </td>
                    <td v-else-if="perguntaItem.somenteNumerico">
                        <b-input type="number" v-model="item.respostas.filter(resposta => resposta.perguntaItem.id == perguntaItem.id)[0].descritivo"></b-input>
                    </td>
                    <td v-else>
                        <b-radio v-model="item.respostas.filter(resposta => resposta.pergunta.id == pergunta.id)[0].perguntaItem.id"
                            :name="'pergunta_' + pergunta.id"
                            :native-value="perguntaItem.id">
                            {{ perguntaItem.texto }}
                        </b-radio>
                    </td>
                </tr>
            </table>
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-primary" v-show="respostasPesquisas != null && respostasPesquisas.length > 0"
                                :loading="salvandoDados"
                                @click="salvarAlteracaoRespostas">
                        {{$t('MODALRESPOSTAPESQUISA.SALVAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import utilFile from '../../assets/js/views/atendimento/utilFile.js'    

    export default {
        props: {
            respostasPesquisas: Array,
            exames: Array,
        },
        data(){
            return {
                respostasAlteracao: [],
                arquivoInserido: [],
                salvandoDados: false,
                formData: new FormData()
            }
        },
        computed: {
            ...mapState([
                'config'
            ])          
        },
        methods: {
                                  

            incluirAnexo(file) {
                                                      
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Arquivo '+ file.name + ' carregado com sucesso!',
                    type: 'is-success'
                })
                    

            },                           
            downloadFileBuscar(incremento, extensao, caminho, item, perguntaId, perguntaItemId){

                const data = new FormData()
                data.append('caminho', caminho)

                this.$http.post('/api/atendimento/BuscarArquivoResposta', data)
                    .then(response => {
                        this.downloadFile(incremento, extensao, response.data);
                        
                    }, error => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: error.message,
                            type: 'is-danger'
                        })
                    })
                
            },
            downloadFile(incremento, extensao, base64Data) {

                let contentType = 'application/' + extensao;

                if(extensao == 'png' || extensao == 'jpg' || extensao == 'jpeg' || extensao == 'gif'){
                    contentType = 'image/' + extensao;
                    const linkSource = `data:${contentType};base64,${base64Data}`;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = "RespostaClinica." + extensao;
                    downloadLink.click();
                }
                else if(extensao == 'txt' || extensao == 'xml' || extensao == 'json'){
                    const element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(base64Data));
                    element.setAttribute('download', "RespostaClinica." + extensao);

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                }
                else {
                    const blob = this.converBase64toBlob(base64Data, contentType);
                    const blobURL = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    document.body.appendChild(link);
                    link.href = blobURL;
                    link.download = "RespostaClinica." + extensao;
                    link.click();
                    window.URL.revokeObjectURL(blobURL);
                    link.remove();
                }
            },
            retornaNomeArquivo(arquivo){
                let nomeArquivo ='';
                if(arquivo){
                    if(arquivo.descritivo){
                        nomeArquivo = arquivo.descritivo.split("files")[1];                                        
                    }
                    
                }
                return nomeArquivo;
            },
            converBase64toBlob(content, contentType) {
                contentType = contentType || '';
                const sliceSize = 512;
                const byteCharacters = window.atob(content);
                const byteArrays = [
                ];
                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                const blob = new Blob(byteArrays, {
                    type: contentType
                });
                return blob;
            },
            async salvarAlteracaoRespostas(){
                let arquivos = '';
                const arquivosOtimizados = [];

                this.arquivoInserido.forEach((arq, index) => {
                    if(arq != null && arq != '' && arq != undefined){                        
                        arquivosOtimizados.push({index: index, arquivo: arq });
                    }
                });
                

                for(let i = 0; i < arquivosOtimizados.length;i++){
                    
                    //Verifica se o arquivo é uma imagem
                    if(utilFile.isFileImage(arquivosOtimizados[i].arquivo)){
                        
                        //Faz a leitura do arquivo e retorna um Blob
                        const newBlob = await utilFile.createFileReader(arquivosOtimizados[i].arquivo); 
                        //Recebe um novo Blob com uma imagem otimizada
                        const newFile = await utilFile.resizeImage(newBlob,arquivosOtimizados[i].arquivo.type,arquivosOtimizados[i].arquivo.name,parseFloat(this.config.percentualOtimizacaoImagem)); 
                        this.formData.append('files', newFile, newFile.name);
                    
                    //Caso contrário segue fluxo normal para salvar o arquivo
                    }else{
                        this.formData.append('files', arquivosOtimizados[i].arquivo, arquivosOtimizados[i].arquivo.name);
                    }
                    arquivos += ',' + arquivosOtimizados[i].index;                    
                    
                }

                this.formData.append('alterandoRespostas', true)
                this.formData.append('arquivosAlterados', arquivos.substring(1))

                //TODO: deixar dessa forma para não precisar refazer o método de salvar no back-end, por enquanto
                this.respostasPesquisas.forEach((respostaPesquisa, i) => {
                    let guiaExameIds = ''
                    respostaPesquisa.guiaExameIds.forEach(guiaExameId => {
                        guiaExameIds += ',' + guiaExameId.guiaId + "_" + guiaExameId.item
                    })

                    if(respostaPesquisa.respostas == null || respostaPesquisa.respostas.length == 0){
                        respostaPesquisa.respostas = [];

                        respostaPesquisa.pesquisa.perguntas.forEach((pergunta, index) => {
                            pergunta.perguntaItens.forEach((perguntaItem, j) => {
                                if(perguntaItem.aceitaData){
                                    perguntaItem.descritivo = this.$moment(perguntaItem.descritivoData).format()
                                }

                                this.formData.append("lista[" + i + "].Respostas["+ index +"].GuiaExameIds", guiaExameIds.substring(1));
                                this.formData.append("lista[" + i + "].Respostas["+ index +"].Pergunta.Id", pergunta.id);
                                this.formData.append("lista[" + i + "].Respostas["+ index +"].PerguntaItem.Id", perguntaItem.id);
                                this.formData.append("lista[" + i + "].Respostas["+ index +"].PerguntaItem.AceitaData", perguntaItem.aceitaData);
                                this.formData.append("lista[" + i + "].Respostas["+ index +"].PerguntaItem.AceitaDescritivo", perguntaItem.aceitaDescritivo);
                                this.formData.append("lista[" + i + "].Respostas["+ index +"].PerguntaItem.AceitaArquivo", perguntaItem.aceitaArquivo);

                                if(perguntaItem.descritivo != null && perguntaItem.descritivo != '' && perguntaItem.descritivo != undefined){
                                    this.formData.append("lista[" + i + "].Respostas["+ index +"].Descritivo", perguntaItem.descritivo);
                                }

                            })
                        })
                    }

                    respostaPesquisa.respostas.forEach((resposta, j) => {

                        if(resposta.perguntaItem.aceitaData){
                            resposta.descritivo = this.$moment(resposta.descritivoData).format()
                        }
                        this.formData.append("lista[" + i + "].Respostas["+ j +"].GuiaExameIds", guiaExameIds.substring(1));
                        this.formData.append("lista[" + i + "].Respostas["+ j +"].Pergunta.Id", resposta.pergunta.id);
                        this.formData.append("lista[" + i + "].Respostas["+ j +"].PerguntaItem.Id", resposta.perguntaItem.id);
                        this.formData.append("lista[" + i + "].Respostas["+ j +"].PerguntaItem.AceitaData", resposta.perguntaItem.aceitaData);
                        this.formData.append("lista[" + i + "].Respostas["+ j +"].PerguntaItem.AceitaDescritivo", resposta.perguntaItem.aceitaDescritivo);
                        this.formData.append("lista[" + i + "].Respostas["+ j +"].PerguntaItem.AceitaArquivo", resposta.perguntaItem.aceitaArquivo);

                        if(resposta.descritivo != null && resposta.descritivo != '' && resposta.descritivo != undefined){
                            this.formData.append("lista[" + i + "].Respostas["+ j +"].Descritivo", resposta.descritivo);
                        }
                    })
                });

                this.salvandoDados = true;
                                                
                this.$http.post('/api/atendimento/PesquisaClinicaRespostas', this.formData)
                .then(response => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Respostas clínicas salvas com sucesso.',
                        type: 'is-success'
                    })
                    
                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                })
                .finally(() => {
                    this.salvandoDados = false;
                    this.$router.go();
                })
            }
        }
    }
</script>