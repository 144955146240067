var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('titlebar',{attrs:{"title-stack":_vm.titleStack}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"tile is-ancestor"},[_c('div',{staticClass:"tile is-parent is-vertical is-9"},[_c('article',{staticClass:"tile is-child box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"código"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('ABREVIATURACADASTRO.CODIGO'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text","maxlength":"15","has-counter":false,"readonly":_vm.$route.params.id},model:{value:(_vm.model.id),callback:function ($$v) {_vm.$set(_vm.model, "id", $$v)},expression:"model.id"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('ABREVIATURACADASTRO.NOME'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text","has-counter":false},model:{value:(_vm.model.nome),callback:function ($$v) {_vm.$set(_vm.model, "nome", $$v)},expression:"model.nome"}})],1)]}}],null,true)})],1)])])]),_c('div',{staticClass:"tile is-parent"},[_c('article',{staticClass:"tile is-child"})])]),_c('nav',{staticClass:"level"},[_c('div',{staticClass:"level-item"},[_c('p',{staticClass:"buttons"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isInRole('mascaraAbreviatura-alterar')),expression:"isInRole('mascaraAbreviatura-alterar')"}],attrs:{"type":"is-success","loading":_vm.salvandoDados,"native-type":"submit","icon-left":"check-circle"}},[_vm._v(" "+_vm._s(_vm.$t('ABREVIATURACADASTRO.SALVAR'))+" ")]),_c('b-button',{attrs:{"type":"is-danger","native-type":"button","icon-left":"close-circle"},on:{"click":function($event){return _vm.$router.back();}}},[_vm._v(" "+_vm._s(_vm.$t('ABREVIATURACADASTRO.CANCELAR'))+" ")])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }