<template>
    <section>        
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>

        <b-modal :active.sync="isModalAgendamento" has-modal-card trap-focus aria-role="dialog" aria-modal full-screen>
            <modalNovoAgendamento :id="novo.id" :observacao="novo.observacao" :data.sync="novo.data" :habilitarVisaoDiario="true"  
            :idTipoAgendamento.sync="novo.idTipoAgendamento" :idPaciente.sync="novo.idPaciente" :paciente.sync="novo.paciente" 
            :tipoAgendamento.sync="novo.tipoAgendamento" :funcionarioId.sync="novo.funcionarioId" :funcionariosAtivos.sync="novo.funcionariosAtivos" 
            @novo="adicionarAgendamento"></modalNovoAgendamento>
        </b-modal>
        <b-modal :active.sync="isModalImpressao" has-modal-card trap-focus aria-role="dialog" aria-modal>
            <modalImpressaoAgendamento :id="imprimir.id" :modelos="model.comprovanteModelos" @imprimir="imprimirModelo"></modalImpressaoAgendamento>
        </b-modal>

        <div class='box' >
            <div class="level">
                <div class="level-item">
                    <router-link :to="{ name: 'guia', params: { id: this.id }}" class="button is-primary">
                        {{ this.id }}
                    </router-link>                  
                </div>
                <div class="level-item">
                    <router-link  :to="{ name: 'agenda'}" class="button is-primary">
                          <b-icon icon="calendar"></b-icon>  <span>Ir para agenda</span>
                    </router-link>   
                </div>                
            </div>
        </div>        

        <article class="panel">
            <div class="panel-heading has-text-centered">                
                {{$t('WORKLIST.AGENDAMENTOEXAMES')}}
            </div>
            <b-table :data="model.agendaWorklist"                        
                    :selected.sync="selected"
                    focusable >

                <template slot-scope="props" >
                   <b-table-column field="id" :label="$t('WORKLIST.PACIENTE')" >
                         {{ props.row.pacienteNome }}
                   </b-table-column>
                  <b-table-column field="convenio" :label="$t('WORKLIST.CONVENIO')" >
                         {{ props.row.convenioNome }}
                    </b-table-column>
                  <b-table-column field="medico" :label="$t('WORKLIST.MEDICO')" >
                         {{ props.row.medicoNome }}
                    </b-table-column>
         
                  <b-table-column field="examenome" :label="$t('WORKLIST.EXAME')" >
                         {{ props.row.exameNome }}
                    </b-table-column>   
                  <b-table-column field="tipoagendamento" :label="$t('WORKLIST.AGENDA')" >
                         {{ props.row.tipoAgendamentoNome }}
                        <b-icon v-if="props.row.tipoAgendamentoCor"
                                :style="tipoAgendamentoStyle(props.row.tipoAgendamentoCor)"    
                                class="is-medium" icon="square-rounded"></b-icon>                         
                    </b-table-column>           
                  <b-table-column field="agendamento" :label="$t('WORKLIST.AGENDAMENTOREALIZADO')"  >
                          {{ props.row.agendamento }}
                    </b-table-column>  
                </template>                                                                                                            
    
            </b-table>            
        </article>

        <div v-show="selected" class="columns is-multiline whitebox"> 
            <div class="column">
                <zoom :classe="'agendaItem'" :max="1.8" :min="0.5" />
            </div>                                
            <div class="column is-12 has-text-centered whitebox" > 

                    <b-button class="is-pulled-left"  icon-left="chevron-left" @click="addDays(-1);" ></b-button>                                    
                    <span class="is-size-3"><b>{{$moment(dataDiaFiltro).format('DD/MM/YYYY')}}</b></span>                                     
                    <b-button class="is-pulled-right" icon-left="chevron-right"  @click="addDays(1);" ></b-button> 

            </div>                                 
            
            <agenda-item  v-if="eventosDia.length>0" :eventos="eventosDia" :tipoVisao="'dia'" v-on:loadData="loadDataDia()" :agendaWorklist="true"
                 @abrirImprimir="abrirImprimir" @incluirNovoHorarioDia="incluirNovoHorarioDia" ></agenda-item>                                     
            <div v-else class="notification column is-12 is-warning">
                <h4>{{$t('AGENDA.ESCALASNAOCADASTRADAS')}}</h4>                                    
            </div>                                                              
    
        </div>     

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                :loading="salvandoDados"
                                @click="salvarWorklist"
                                icon-left="check-circle">
                        {{$t('WORKLIST.SALVAR')}}
                    </b-button>
                </p>
            </div>
        </nav>             

    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import { mapState } from 'vuex'
    import agendaItem from '@/components/atendimento/agenda-item.vue';
    import zoom from "@/components/zoom.vue";
    import moment from 'moment';  
    import modalNovoAgendamento from '@/components/atendimento/modalNovoAgendamento.vue';
    import modalImpressaoAgendamento from '@/components/atendimento/modalImpressaoAgendamento.vue';   


    export default {
        data() {
            return {
                id: this.$route.params.id,
                pagamentoGuia: this.$route.params.pagamentoGuia,
                condicaoPagamento: this.$route.params.condicaoPagamento,
                convenioCoparticipacao: this.$route.params.convenioCoparticipacao,                
                selected:null,
                model: [],
                tipoAgendamentoIdFiltro: 27,
                dataDiaFiltro: new Date(new Date().setHours(0,0,0,0)),
                isLoading: false, 
                eventosDia: [],  
                isModalAgendamento: false,                
                isModalImpressao: false,   
                imprimir: { id: null },
                novo: {
                    data: null,
                    observacao: null,
                    idPaciente: null,
                    idTipoAgendamento: null,
                    funcionarioId: null,
                    paciente: {},
                    tipoAgendamento: {}
                },                                                          
            }
        },
        components: {
            titlebar,
            agendaItem,
            modalNovoAgendamento,
            modalImpressaoAgendamento,            
            zoom
        },
        computed: {
            titleStack() {
                return [
                    this.$t('WORKLIST.ATENDIMENTO'),
                    this.$t('WORKLIST.WORKLIST'),
                    this.id
                ]
            },
            ...mapState([
                'config',
            ])
        },
        watch: {
            selected: function(selected) {                            
                this.$forceUpdate();
                this.loadDataDia();                                                
            }
        },          
        created() {
            this.salvandoDados = true;
            this.carregarWorklist();
                   
        },
        methods: {
            carregarWorklist(){
                this.$http.get(`/api/atendimento/Worklist?id=${this.$route.params.id}`)
                    .then(({ data }) => {
                        this.model = data;

                        this.model.agendaWorklist = data.agendaWorklist
                            .map(d => {
                                return {
                                    ...d,
                                    agendamento: (d.agendamento) ? this.$moment(d.agendamento).format('DD/MM/YYYY HH:mm') : null
                                }                                           
                            });                    
                                            
                        this.salvandoDados = false;
                    })
                    .catch((error) => {
                        this.model = null
                        throw error
                    })
                    .finally(() => {
                        this.salvandoDados = false
                    }) 
            },
            tipoAgendamentoStyle(cor) {
                return 'color: #' + cor + '!important'
            },     
            salvarWorklist(){

                    let finalizarAgendamento = true;
                    this.model.agendaWorklist.forEach(e => {
                        if(e.agendamento == null)
                           finalizarAgendamento = false; 

                        
                    });
                    
                    if(finalizarAgendamento){
                        
                            if(this.pagamentoGuia && (this.condicaoPagamento  != 0 || this.convenioCoparticipacao)){
                                this.$router.push({ name: 'contas-receber-particular', 
                                    params: { 
                                        guiaId: this.id, 
                                        condicaoPagamento: this.condicaoPagamento
                                    }
                                });
                            }
                            else if (this.id) {
                                this.$router.push({ name: 'workflow', 
                                    params: { 
                                        id: this.id
                                    }
                                });
                            }

                    }else{
                        this.$buefy.dialog.alert({
                            title: 'Atenção',
                            message: 'Existem Exames que ainda não foram agendados!',
                            type: 'is-warning',
                            hasIcon: true
                        });
                    }                

                return true;
            },  
            
            addDays(dia){
                this.dataDiaFiltro.setDate(this.dataDiaFiltro.getDate() + dia);                                
                this.loadDataDia();                                
            }, 
            getEvento(d) {
                return {
                    ...d,
                    atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                    atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                    pacienteId: this.selected.pacienteId,                    
                    day: this.$moment(d.dataHora).format('YYYY-MM-DD'),
                    hour: this.$moment(d.dataHora).format('HH:mm'),
                    data: this.$moment(d.dataHora)
                }
            },            
            loadDataDia(){
                const params = [];

                if (this.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(this.dataDiaFiltro).format()}`);                                                     
                if(this.selected.tipoAgendamentoId) params.push(`tipoAgendamentoId=${this.selected.tipoAgendamentoId}`);
                this.isLoading = true;

                try {
                    this.$http.get(`/api/atendimento/AgendaDia?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.eventosDia = data?.lista                                    
                                    .map(d => {
                                        return this.getEvento(d);
                                    });
                                   
                            }
                            this.isLoading = false;    
                            this.$forceUpdate();                                                                                  
                        }).catch(e => {
                            throw e;
                        });
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }                
                
            },  
            incluirNovoHorarioDia(evento, horario){                
                const novo = {
                    id: evento.id,
                    paciente: {
                        nome: evento.pacienteNome,
                        id: evento.pacienteId,
                    },
                    tipoAgendamento: {
                        nome: evento.tipoAgendamentoNome,
                        id: evento.tipoAgendamentoId,
                        cor: evento.cor
                    },
                    idPaciente: evento.pacienteId,
                    funcionarioId: evento.funcionarioId,
                    idTipoAgendamento: evento.tipoAgendamentoId,
                    observacao: evento.observacao,
                    data: horario
                }

                this.abrirNovoEvento(horario, novo);
            },
            abrirNovoEvento(day, novo = null) {
                let dataAgendamento = moment(day.id).toDate();
                //caso seja hoje, empurrar o horário do agendamento para uma hora à frente (Evitar problema com a data menor que a data atual)
                if (moment(dataAgendamento).isSameOrBefore(moment())) {
                    dataAgendamento = moment().add('hour', 1).set('m', 0).toDate();
                }

                this.novo = novo ? novo : {
                    data: dataAgendamento,
                    observacao: null,
                    idPaciente: null,
                    idTipoAgendamento: null,
                    funcionarioId: null,
                    paciente: {},
                    tipoAgendamento: {},
                }                
                this.isModalAgendamento = true;
            },                        
            abrirImprimir(item) {                
                this.imprimir = { id: null }
                this.isModalImpressao = true;
                this.imprimir.id = item.id;
            },            
            imprimirModelo(data) {
                const form = document.createElement("form");
                const inputId = document.createElement("input");
                inputId.name = 'id';
                const inputModelo = document.createElement("input");
                inputModelo.name = 'modelo';

                form.method = "POST";
                form.action = "/api/atendimento/Agendamento";

                inputId.value = this.imprimir.id;
                inputModelo.value = data.nome;

                form.appendChild(inputId);
                form.appendChild(inputModelo);

                data.variaveis?.forEach(v => {
                    const input = document.createElement("input");
                    input.name = v.nome;
                    input.value = v.valor;
                    form.appendChild(input);
                });


                document.body.appendChild(form);
                form.setAttribute('target','_blank');
                form.submit();
            },
            async adicionarAgendamento(evento) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);
                params.push(`pacienteId=${evento.paciente.id}`);
                params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`);
                if(evento.funcionarioId == null) evento.funcionarioId = 0;
                params.push(`funcionarioId=${evento.funcionarioId}`);
                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);
                params.push(`observacao=${evento.observacao ? evento.observacao : ''}`);
                if (this.id) params.push(`guiaId=${this.id}`);
                if (this.$route.query.unidadeId != null)
                    params.push(`unidadeId=${this.$route.query.unidadeId}`)
                else
                    params.push('unidadeid=');

                try {
                    let url = `/api/atendimento/agendaincluir?${params.join('&')}`;

                    if (evento.id) {
                        url = `/api/atendimento/agendaEditar?${params.join('&')}`;
                    }

                    this.isLoading = true;
                    const res = await this.$http.post(url);   
                    this.carregarWorklist();                 
                    this.loadDataDia();
                } catch (e) {                    
                    if (e.status === 404) {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('AGENDA.ERROAOSALVAR'),
                            type: 'is-danger',
                            queue: false
                        })
                    }
                    console.error(e);
                    this.isLoading = false;
                }

            },                                 
        }
    }
</script>