<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-info">
            <p class="modal-card-title">Atualizar preços dos exames já solicitados</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">
                <div class="panel-block">
                    <b-checkbox v-model="incluirFaturados"
                                size="is-small"
                                type="is-info">
                        Incluir Exames Faturados
                    </b-checkbox>
                </div>
                <div class="panel-block">
                    <periodo :dataInicial.sync="datas[0]"
                            :dataFinal.sync="datas[1]" />
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="atualizarPrecos">Atualizar</b-button>
            <b-button @click="$emit('close')">Fechar</b-button>
        </footer>
    </div>
</template>

<script>
    import periodo from '@/components/periodo.vue'

    export default {
        props: {
            convenioId: Object
        },
        data() {
            return {
                incluirFaturados: '',
                datas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ]
            }
        },
        components: {
            periodo
        },
        methods: {
            atualizarPrecos() {
                let url = '/api/atendimento/AtualizaPrecosConvenio?convenioId=' + this.convenioId;

                if(this.incluirFaturados){
                    url += '&incluirFaturados=' + this.incluirFaturados;
                }

                url += '&dataInicial=' + this.$moment(this.datas[0]).format() +
                    '&datafinal=' + this.$moment(this.datas[1]).format()

                this.$http.get(url)
                    .then(res => res.data)
                    .then(data => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: data,
                            type: 'is-info',
                            queue: false
                        });
                        this.$emit('close');
                    })
            },

        }
    }
</script>