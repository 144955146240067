<template>
  <section>
    <article class="panel">
      <div class="panel-heading has-text-centered">
        {{ label }}
      </div>
      <div class="panel-block">
        <b-table
          :data="interno.items"
          class="table"
          striped
          hoverable
          :opened-detailed="defaultOpenedDetails"
          detail-key="id"
          :show-detail-icon="false"
          detailed
        >
          <template slot-scope="props" v-if="props.row.show">          
            <b-table-column field="id" :label="$t('LIBERACAOAUTOMANUTENCAO.CODIGO')">
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="mascara" :label="$t('LIBERACAOAUTOMANUTENCAO.MASCARA')">
              <b-select
                placeholder=""
                v-model="props.row.mascara.versao"
                expanded
                @input="filtrarCampos(props.row,props.row.mascara.versao)"
                required
              >
                <option
                  v-for="option in interno.mascaras"
                  :value="option.versao"
                  :key="getKey(option.versao)"
                >
                  {{ option.versao }}
                </option>
              </b-select>
            </b-table-column>
            <b-table-column field="campos" :label="$t('LIBERACAOAUTOMANUTENCAO.CAMPO')">
              <b-select placeholder="" v-model="props.row.campo.id" expanded @input="change" required>
                <option
                  v-for="option in props.row.campos"
                  :value="option.id"
                  :key="getKey(option.id)"
                >
                  {{ option.nome }}
                </option>
              </b-select>
            </b-table-column>
            <b-table-column field="sexo" :label="$t('LIBERACAOAUTOMANUTENCAO.SEXO')">
              <b-select placeholder="" v-model="props.row.sexo" expanded @input="change">
                <option
                  v-for="option in interno.sexo"
                  :value="option.value"
                  :key="option.value"
                >
                  {{ option.text }}
                </option>
              </b-select>
            </b-table-column>
            <b-table-column field="idadeMaiorQue" :label="$t('LIBERACAOAUTOMANUTENCAO.IDADEMAIOR')">
              <b-field>
                <b-input
                  type="number"
                  step="0.0001"
                  v-model="props.row.idadeMaiorQue"
                  ref="fieldFocus"
                  @input="change"
                ></b-input>
              </b-field>
            </b-table-column>
            <b-table-column field="idadeMenorIgualQue" :label="$t('LIBERACAOAUTOMANUTENCAO.IDADEMENOR')">
              <b-field>
                <b-input
                  type="number"
                  step="0.0001"
                  v-model="props.row.idadeMenorIgualQue"
                  @input="change"
                ></b-input>
              </b-field>
            </b-table-column>
            <b-table-column field="valorMinimo" :label="$t('LIBERACAOAUTOMANUTENCAO.VALMIN')">
              <b-field>
                <b-input
                  type="text"
                  step="0.0001"
                  v-model="props.row.valorMinimo"
                  @input="change"
                ></b-input>
              </b-field>
            </b-table-column>
            <b-table-column field="valorMaximo" :label="$t('LIBERACAOAUTOMANUTENCAO.VALMAX')">
              <b-field>
                <b-input
                  type="number"
                  step="0.0001"
                  v-model="props.row.valorMaximo"
                  @input="change"
                ></b-input>
              </b-field>
            </b-table-column>
            <b-table-column field="campoUnico" :label="$t('LIBERACAOAUTOMANUTENCAO.CAMPOUN')">
              <b-field>
                <b-checkbox
                  v-model="props.row.campoUnico"
                  @input="campoUnicoAlterado(props.row, $event)"
                ></b-checkbox>
              </b-field>
            </b-table-column>
            <b-table-column field="habilitada" :label="$t('LIBERACAOAUTOMANUTENCAO.HABILITADA')">
              <b-field>
                <b-checkbox v-model="props.row.habilitada" @input="change"></b-checkbox>
              </b-field>
            </b-table-column>
            <b-table-column field="" label="">
              <b-field>
                <b-button
                  type="is-info"
                  native-type="button"
                  icon-left="clock"
                  class="button-delete"
                  @click="exibirHistorico(props.row)"
                ></b-button>
              </b-field>
            </b-table-column>
          </template>

          <template slot="detail" slot-scope="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <table>
                    <tr v-for="item in props.row.proximaLiberacao.examesCorrelacionados" :key="item.id">
                      <td>
                        <searchIdName
                          :label="$t('LIBERACAOAUTOMANUTENCAO.PROXLIB')"
                          table="Liberacao"
                          field="resumo"
                          searchField="texto"
                          @item="selectItem($event,item, props.row.proximaLiberacao)"
                          :id="props.row.proximaLiberacao.id"
                        ></searchIdName>
                      </td>
                      <td>
                        <div class="columns">
                          <div class="column">
                            <b-field :label="$t('LIBERACAOAUTOMANUTENCAO.PRIMEIROCAMPO')">
                              <b-checkbox
                                v-model="props.row.primeiroCampo"
                                @input="verificaCampoUnico(props.row) && change()"
                              ></b-checkbox>
                            </b-field>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="columns">
                          <div class="column">
                            <b-field :label="$t('LIBERACAOAUTOMANUTENCAO.ULTIMOCAMPO')">
                              <b-checkbox
                                v-model="props.row.ultimoCampo"
                                @input="verificaCampoUnico(props.row) && change()"
                              ></b-checkbox>
                            </b-field>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </article>
          </template>
        </b-table>
        <div class="buttons has-text-centered">
          <b-button
            type="is-success"
            native-type="button"
            icon-left="plus"
            class="center"
            @click="addItem()"
          >
           {{$t('LIBERACAOAUTOMANUTENCAO.INCLUIR')}}
          </b-button>
        </div>
      </div>
    </article>
  </section>
</template>

<style scoped>
.button-delete {
  max-height: 1.5rem;
}

.table {
  width: 100%;
  display: block;
  /* max-height: 30vh;
  overflow-y: auto; */
}

.panel-block {
  display: block;
}

.center {
  margin: 0 auto;
}
</style>

<script>
import debounce from "lodash/debounce";
import searchIdName from "@/components/searchIdName.vue";

export default {
  props: {
    items: null,
    mascaras: null,
    label: String,
    sexo: null,
    filtroMascara: null,
    filtroCampo: null,
    apelido: null,
    novosItems: null
  },
  data() {
    return {
      data: [],
      inputid: this.id,
      isFetching: false,
      interno: {
        items: [],
        mascaras: [],
        sexo: []
      },
      defaultOpenedDetails: [],
    };
  },
  components: {
    searchIdName
  },
  mounted() {
    this.interno.sexo = this.sexo;
    this.interno.mascaras = this.mascaras;
    this.interno.items = this.getItems();
  },
  watch: {
    novosItems(novoValor){
      this.interno.items.push(...novoValor);
      this.$buefy.toast.open({
            duration: 5000,
            message: this.$t('LIBERACAOAUTOMANUTENCAO.SUCESSO'),
            type: "is-success",
            queue: false,
          });
      window.scrollTo(0,document.body.scrollHeight);
      this.change();

    },
    items() {   
      this.interno.items = this.getItems();
        this.interno.items.forEach((i, index) => {          
          this.defaultOpenedDetails.push(i.id);
          });
        },
    mascaras() {
      this.interno.mascaras = this.mascaras;
    },
    sexo() {
      this.interno.sexo = this.sexo;
    },
    filtroMascara(novoValor){
      if (novoValor == '' || !novoValor){
        this.interno.items = this.getItems();  
      }else{
        this.interno.items = this.getItems().filter(c => c.mascara?.versao === novoValor?.versao);
      }

    },
    filtroCampo(novoValor){      
      if (novoValor == '' || !novoValor){
        this.interno.items = this.getItems().filter(c => c.mascara?.versao === this.filtroMascara?.versao);
      }else{
        this.interno.items = this.getItems().filter(c => c.campo?.id === novoValor);
      }
    }
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$scopedSlots.default;
    },
  },
  methods: {
    addItem() {
      const versao = this.mascaras[0]?.versao;
      let campo = {id: 0};
      if (this.filtroCampo){
        campo = this.mascaras?.filter(c => c.versao == versao)[0]?.campos?.find(c => c.id == this.filtroCampo);
      }else{
        campo = this.mascaras.filter(c => c.versao == versao)[0]?.campos[0];
      }
      this.interno.items.push({
        id: 0,
        mascara: {versao: this.mascaras[0]?.versao},
        campo: Object.assign({},campo),
        sexo: this.sexo[0]?.value,
        habilitada: false,
        campoUnico: true,
        idadeMaiorQue: null,
        idadeMenorIgualQue: null,
        primeiroCampo: true,
        proximaLiberacao: {id: 0, examesCorrelacionados: [] },
        strExamesCorrelacionados: null,
        ultimoCampo: true,
        valorMaximo: null,
        valorMinimo: null,
        campos: this.mascaras.filter(c => c.versao == versao)[0]?.campos?.map(c => c),
        show: true
      });
      setTimeout(() => {
        this.$refs.fieldFocus.focus();
      }, 100);
    },
    deleteItem(item) {
      item.show = false;
      item.id = `${item.id}_0`;
      this.change();
    },
    change() {
      const itemFiltrado = this.interno.items?.map(i => {
        i.touched = true;
        delete i.campo?.valoresDeReferencia;
        return i;
      });

      this.$emit("update:items", itemFiltrado);
    },
    changeCampoUnico() {
      const itemFiltrado = this.interno.items?.map(i => {
        if(i.campoUnico){
          i.ultimoCampo = true;
          i.primeiroCampo = true;
        }
        i.touched = true;
        delete i.campo?.valoresDeReferencia;
        return i;
      });
      this.$emit("update:items", itemFiltrado);
    },    
    campoUnicoAlterado(row, habilitado) {
      if (!habilitado) {
        if (this.defaultOpenedDetails?.indexOf(row.id) < 0) {
          row.primeiroCampo = true;
          row.ultimoCampo = true;
          this.defaultOpenedDetails.push(row.id);
        }
        if (!row.proximaLiberacao?.examesCorrelacionados?.find(e => e.id === null)){
          if (!row.proximaLiberacao?.id){
            row.proximaLiberacao.id = 0;
          }
          if (!row.proximaLiberacao){
            row.proximaLiberacao = {id: 0};
          }
          if (!row.proximaLiberacao?.examesCorrelacionados){
            row.proximaLiberacao.examesCorrelacionados = [];
          }
          row.proximaLiberacao.examesCorrelacionados.push({id: null, resumo: null});
        }
      } else {
        this.defaultOpenedDetails = this.defaultOpenedDetails.filter(
          (i) => i != row.id
        );
        row.proximaLiberacao = {id: 0, examesCorrelacionados: []}
        row.ultimoCampo = false;
        row.primeiroCampo = false;        
      }
      this.changeCampoUnico();
    },
    verificaCampoUnico(row) {
      this.change();
      // if (!row.primeiroCampo && !row.ultimoCampo) {
      //   row.campoUnico = true;
      // }
    },
    exibirHistorico(row){
      this.$emit('historico',row);
    },
    getItems(){
      return this.items?.map((i) => {
        return {
          ...i,
          valorMinimo: i.valorMinimo?.toString().trim(),
          campoUnico: ( (i.primeiroCampo) && (i.ultimoCampo) ),
          proximaLiberacao: i.proximaLiberacao?.id ? {id: i.proximaLiberacao.id, examesCorrelacionados: [{id: 0}]} : {id: 0, examesCorrelacionados: [{id: 0}]},
          campos: this.mascaras.filter(c => c.versao == i.mascara?.versao)[0]?.campos?.map(c => c),
          show: true,
        };
      });
    },
    selectItem(valor,item, proxliberacao){
      item.resumo = valor.resumo;
      item.nome = valor.resumo;
      item.apelido = this.apelido;
      if(valor.data){
        item.idSelecionado = valor.data.id;
      }
      else {
        item.idSelecionado = 0;
      }

      proxliberacao.id = item.idSelecionado;
      this.change();
    },
    filtrarCampos(row, versao){
      row.campos = this.interno.mascaras.filter(c => c.versao == versao)[0]?.campos?.map(c => c);
      row.campo = row.campo ? Object.assign({},row.campo) : Object.assign({},row.campos[0]);
      this.$forceUpdate();
      this.change();
    },
    getKey(valor){
      return `${valor}_${Math.random()}`;
    }
  },
};
</script>
