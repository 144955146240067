<template>
    <section>
        <div class="columns">
            <div class="column is-one-quarter is-half-mobile">
                <b-field :label="$t('BUSCACEP.CEP')" 
                         :type="{ 'is-danger': naoLocalizado, 'is-success': encontrado }"
                         :message="naoLocalizado ? $t('BUSCACEP.CEPMESSAGEM') : naoLocalizado">
                    <b-input :loading="buscando" 
                             v-model="interno.cep"
                             @blur="buscarCep"
                             @keyup="$emit('update:cep', interno.cep);"
                             v-mask="'#####-###'"
                             :icon-right="!buscando && !encontrado && !naoLocalizado ? 'earth' : ''"></b-input>
                </b-field>
            </div>
            <div class="column">
                <div class="field">
                    <label class="label">{{$t('BUSCACEP.ENDERECO')}}</label>
                    <div class="control">
                        <input class="input" type="text" maxlength="200" v-model="interno.endereco" @keyup="$emit('update:endereco', interno.endereco);">
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label class="label">{{$t('BUSCACEP.BAIRRO')}}</label>
                    <div class="conntrol">
                        <input class="input" type="text" maxlength="200" v-model="interno.bairro" @keyup="$emit('update:bairro', interno.bairro);">
                    </div>
                </div>
            </div>
            <div class="column">
                <buscaCidade :cidadeid.sync="cidadeId" :cidadenome.sync="cidadenome"></buscaCidade>
            </div>
        </div>
    </section>
</template>

<script>
    import buscaCidade from '@/components/buscaCidade.vue'

    export default {
        components: {
            buscaCidade
        },
        props: {
            cep: String,
            endereco: String,
            bairro: String,
            cidadeid: Number,
            cidadenome: String,
            erroCidade: String
        },
        data() {
            return {
                interno: {
                    cep: this.cep,
                    endereco: this.endereco,
                    bairro: this.bairro
                },
                cidadeId: this.cidadeid,
                buscando: false,
                encontrado: false,
                naoLocalizado: false
            }
        },
        watch: {
            cep() {
                this.interno.cep = this.cep
            },
            endereco() {
                this.interno.endereco = this.endereco
            },
            bairro() {
                this.interno.bairro = this.bairro
            },
            cidadeId(){
                this.$emit('update:cidadeid', this.cidadeId)
            }           
        },
        methods: {
            buscarCep() {
                this.buscando = false
                this.encontrado = false
                this.naoLocalizado = false

                if (/^[0-9]{5}-[0-9]{3}$/.test(this.interno.cep)) {
                    this.buscando = true;
                    const url = 'https://viacep.com.br/ws/' + this.interno.cep + '/json/';

                    fetch(url)
                        .then(res => res.json())
                        .then(endereco => {
                            this.buscando = false
                            if (endereco.erro) {
                                this.naoLocalizado = true
                            } else {
                                this.encontrado = true
                                this.$emit('update:cep', endereco.cep)
                                this.$emit('update:endereco', endereco.logradouro)
                                this.$emit('update:bairro', endereco.bairro)
                                this.$emit('update:cidadeid', parseInt(endereco.ibge))
                                this.$emit('update:cidadenome', endereco.localidade + " - " + endereco.uf)
                                this.interno.endereco = endereco.logradouro;
                                this.interno.bairro = endereco.bairro;
                            }
                        })
                        
                }
            }
        }
    }
</script>