<template>
    <section>

        <b-modal :active.sync="isFiltroModalActive" full-screen>
            <filtros :tipo="'Medico'" @filtrar="filtrar($event)" @fechar="fecharModal()"></filtros>
        </b-modal>

        <boasVindas></boasVindas>

        <pesquisas :pesquisas="pesquisas" :tipo="'Medico'"></pesquisas>

        <div v-if="isLoading">
            <div class="box">
                <div class="panel">
                    <div class="panel-block columns is-multiline is-mobile">
                        <div class="column is-10-desktop">
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-6-desktop is-full-mobile">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                                <div class="column is-2-desktop is-half-mobile">
                                    <b-skeleton width="60%" animated></b-skeleton>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                                <div class="column is-2-desktop is-half-mobile">
                                    <b-skeleton width="60%" animated></b-skeleton>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                </div>
                            </div>
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-full-mobile">
                                    <b-skeleton width="80%" animated></b-skeleton>
                                </div>
                            </div>
                        </div>
                        <div class="column is-full-mobile has-text-centered">
                            <div class="columns is-multiline is-mobile">
                                <b-skeleton width="80%" animated></b-skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <guias v-if="model != null" :lista.sync="model.guias" @pageChange="pageChange($event)" :tipo="'Medico'"
                   @filtrar="filtrarModal()" :page.sync="page" :pageCount="pageCount"
                   @ordenar="ordenar($event)" :ordenar.sync="filter.ordenarPor"></guias>
        </div>
    </section> 
</template>

<script>
    import { mapState } from 'vuex'
    import boasVindas from '@/components/portal/boas-vindas.vue'
    import pesquisas from '@/components/portal/pesquisas.vue'
    import guias from '@/components/portal/guias.vue'
    import filtros from '@/components/portal/filtros.vue'

    export default {
        data() {
            return {
                model: null,
                page: 1,
                pageCount: null,
                pesquisas: [],
                isLoading: false,
                filter: {
                    ordenarPor: 'emissao'
                },
                isFiltroModalActive: false
            }
        },
        computed: {
            ...mapState([
                'config',
                'usuario'
            ])
        },
        components: {
            boasVindas,
            pesquisas,
            filtros,
            guias
        },
        methods: {

            filtrar(filtros){
                filtros.ordenarPor = this.filter.ordenarPor;

                this.filter = filtros;

                let filtrosUrl = '?page=' + this.page;

                if(filtros.filtrarPor){
                    filtrosUrl += '&filtrarPor=' + filtros.filtrarPor;
                }

                if(filtros.ordenarPor){
                    filtrosUrl += '&ordenarPor=' + filtros.ordenarPor;
                }

                if(filtros.datas){
                    filtrosUrl += '&dataInicial=' + this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') +
                    '&dataFinal=' + this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss');
                }

                if(filtros.paciente != null){
                    filtrosUrl += '&nomePaciente=' + filtros.paciente;
                }

                this.carregarLista(filtrosUrl);

                this.isFiltroModalActive = false;
            },
            filtrarModal(){
                this.isFiltroModalActive = true;
            },
            fecharModal(){
                this.isFiltroModalActive = false;
            },
            ordenar(ordenar){
                this.filter.ordenarPor = ordenar;
                this.filtrar(this.filter);
            },
            carregarLista(filtrosUrl){
                this.isLoading = true;
                this.$http.get('/api/portal/m' + filtrosUrl)
                    .then(res => {
                        this.model = res.data;
                        this.page = this.model.page;
                        this.pageCount = this.model.pageCount;
                        this.isLoading = false;
                    });
            },
            carregarPesquisas(){
                this.$http.get('/api/portal/MedicoPesquisasSatisfacao')
                    .then(res => {
                        this.pesquisas = res.data;
                    });
            },
            pageChange(page){
                this.page = page;

                let filtrosUrl = '?page=' + this.page + '&filtrarPor=' + this.filter.filtrarPor +
                    '&dataInicial=' + this.$moment(this.filter.datas[0]).format('YYYY-MM-DDTHH:mm:ss') +
                    '&dataFinal=' + this.$moment(this.filter.datas[1]).format('YYYY-MM-DDTHH:mm:ss')+
                    '&ordenarPor=' + this.filter.ordenarPor;

                if(this.filter.paciente != null){
                    filtrosUrl += '&nomePaciente=' + this.filter.paciente;
                }

                this.carregarLista(filtrosUrl);
            }
        },
        created() {
            this.carregarLista('?page=' + this.page);
            this.carregarPesquisas();
        }

    }
</script>