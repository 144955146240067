<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('NIVELDEACESSOCADASTRO.SALVANDONIVEL')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('NIVELDEACESSOCADASTRO.NOME')"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="20"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <article class="panel">
                                        <div class="panel-heading has-text-centered">
                                            {{$t('NIVELDEACESSOCADASTRO.ACESSALOCAIS')}}
                                        </div>
                                        <div class="panel-block">

                                            <b-table class="fullwidth" :data="AcessaSomenteTiposDeLocais" striped >
                                                <template slot-scope="props">                                            
                                                    <b-table-column field="" label="">
                                                        <b-button
                                                            icon-left="close" type="is-danger"
                                                            @click.prevent.stop="deletaTipoDeLocal(props.row)"
                                                        ></b-button>
                                                    </b-table-column>

                                                    <b-table-column field="Id" :label="$t('NIVELDEACESSOCADASTRO.ID')" :readonly="true">
                                                        {{ props.row.NivelTipoDeLocalId.TipoDeLocalId }}
                                                    </b-table-column>

                                                    <b-table-column field="Nome" :label="$t('NIVELDEACESSOCADASTRO.NOME')" >
                                                        <b-input type="text" v-model="props.row.NivelTipoDeLocalId.nome" />
                                                    </b-table-column>                                                    
                                                                                            
                                                </template>                         
                                            </b-table>                                            
                                       
                                        </div>
                                        <div class="box">
                                            <article class="level">
                                                <div class="level-left">
                                                    <b-select :placeholder="$t('NIVELDEACESSOCADASTRO.SELECIONALOCAL')" v-model="tipodelocalid">
                                                        <option v-for="(tipo, index) in model.tiposDeLocais"
                                                                :key="index"
                                                                :value="tipo.id">
                                                            {{ tipo.nome }}
                                                        </option>
                                                    </b-select>
                                                </div>
                                                <div class="level-right">      
                                                    <b-button type="is-info"  @click.prevent.stop="addTipoLocal()" >
                                                        {{$t('NIVELDEACESSOCADASTRO.ADICIONALOCAL')}}
                                                    </b-button>                                                                                                            
                                                </div>
                                            </article>
                                        </div>
                                    </article>
                                </div>
                                <div class="column is-one-third">
                                    <div class="field">
                                        <b-switch v-model="model.administrador">
                                            {{$t('NIVELDEACESSOCADASTRO.ADM')}}
                                        </b-switch>
                                    </div>
                                    <div class="field">
                                        <b-switch v-model="model.acessopelainternet">
                                            {{$t('NIVELDEACESSOCADASTRO.ACESSOINTERNET')}}
                                        </b-switch>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <historico-alteracoes :tabela="'NivelDeAcesso'" :chave="model.id"></historico-alteracoes>
                        </article>                        
                    </div>
                </div>
                <article class="tile is-child box" v-if="model.regrasExistentes != null && model.regrasExistentes.length > 0">
                    <b>{{$t('NIVELDEACESSOCADASTRO.REGRAS')}}</b>
                    <template v-for="(grupo, i) in grupos">
                        <collapse-groups :index="i" :titulo="grupo" v-bind:key="i">
                            <tbody>
                                <tr v-for="(item, index) in model.regrasExistentes.filter(x => x.grupo == grupo)" v-bind:key="index">
                                    <td>
                                        <input @change="alterouNivel(item.nome)" type="checkbox" v-if="model.regras.filter(x => x.nome == item.nome).length > 0" checked>
                                        <input @change="alterouNivel(item.nome)" type="checkbox" v-else>
                                    </td>
                                    <td>{{item.nome}}</td>
                                    <td>{{item.descricao}}</td>
                                </tr>
                            </tbody>
                        </collapse-groups>
                        <br v-bind:key="i">
                    </template>
                </article>
                <br>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('NIVELDEACESSOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('NIVELDEACESSOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import collapseGroups from '@/components/manutencao/collapseGroups.vue'
    import Vue from 'vue'

    export default {
        data() {
            return {
                AcessaSomenteTiposDeLocais:[],
                model: {
                    id: null,
                    nome: null,                    
                    administrador: null,
                    acessopelainternet: null,
                    regras: null,
                    regrasExistentes: null,
                    tiposDeLocais: []
                },
                salvandoDados: false,
                tipodelocalid: null,
                grupos:['Atendimento', 'Análise', 'Interface', 'Integração', 'Manutenção', 'Estoque', 'Financeiro', 'Relatórios']
            }
        },
        components: {
            titlebar,
            historicoAlteracoes,
            collapseGroups
        },
        computed: {
            titleStack() {
                return [
                    this.$t('NIVELDEACESSOCADASTRO.MANUTENCAO'),
                    this.$t('NIVELDEACESSOCADASTRO.NIVEL'),
                    this.model.id ?? 'Novo'
                ]
            },
        },
        methods:{
            onSubmit() {
                this.salvandoDados = true;
                this.model.acessaSomenteTiposDeLocais = this.AcessaSomenteTiposDeLocais;

                this.$http.post(`/api/manutencao/nivel`, this.model )
                    .then(({ data }) => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });       
            },
            addTipoLocal() {

                this.AcessaSomenteTiposDeLocais.push( {
                    NivelTipoDeLocalId:{
                        TipoDeLocalId: this.tipodelocalid,
                        nome: this.model.tiposDeLocais.filter(item => item.id == this.tipodelocalid)[0].nome
                    }
                });
                this.$forceUpdate();
            },
            deletaTipoDeLocal(item){

                this.AcessaSomenteTiposDeLocais = this.AcessaSomenteTiposDeLocais.filter(i => i.NivelTipoDeLocalId.TipoDeLocalId != item.NivelTipoDeLocalId.TipoDeLocalId);                

                this.$forceUpdate();
            },
            alterouNivel(regra){
                if(this.model.regras == null){
                    this.model.regras = [];
                }

                if(this.model.regras.filter(x => x.nome == regra).length == 0){
                    this.model.regras.push({
                        id: this.model.regrasExistentes.filter(x => x.nome == regra)[0].id,
                        nome: this.model.regrasExistentes.filter(x => x.nome == regra)[0].nome
                    });
                }
                else 
                {
                    let index = -1;
                    for (const i in this.model.regras) {
                        if(this.model.regras[i].nome == regra) {
                            index = i;
                            break;
                        }
                    }

                    if(index > -1)
                    {
                        Vue.delete(this.model.regras, index);
                    }
                }
            }
        },
        created() {
            let url = '/api/manutencao/niveldeacesso?id=' + this.$route.params.id
            if(!this.$route.params.id){
                url = '/api/manutencao/niveldeacesso'
            }
            this.$http.get(url)
                .then(res => {
                    this.model = res.data;

                    if(this.model.regras == null){
                        this.model.regras = [];
                    }

                    this.model.acessaSomenteTiposDeLocais.forEach((item, index) => {

                        this.AcessaSomenteTiposDeLocais.push( {
                            NivelTipoDeLocalId:{
                                TipoDeLocalId: item.id,
                                nome: item.nome
                            }
                        });                        

                    });                      
                    
                })
        }
    }
</script>