<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box" v-if="!loading && lista.length == 0">
            Nenhuma informação encontrada. Refaça os filtros e tente novamente.
        </div>
        <div class="box" v-if="loading">
            <b-skeleton animated active></b-skeleton>
        </div>
        <b-table v-if="lista.length > 0" :data="lista"
                 :loading="loading"
                 striped
                 hoverable>

            <template slot-scope="props">
                <b-table-column field="idMovimentacao" label="Movimentacao">
                    <a :href="'/Estoque/EstoqueMovimentacao/' + props.row.idMovimentacao">{{ props.row.idMovimentacao }}</a>
                </b-table-column>

                <b-table-column field="estoque" label="Estoque">
                    <a :href="'/Estoque/Estoque/' + props.row.idEstoque">{{ props.row.nomeEstoque }}</a>
                </b-table-column>

                <b-table-column field="lote" label="Lote">
                    <a :href="'/Estoque/Lote/' + props.row.idLote">{{ props.row.nomeLote }}</a>
                </b-table-column>

                <b-table-column field="consumivel" label="Consumivel">
                    <a :href="'/Estoque/Consumivel/' + props.row.idConsumivel">{{ props.row.nomeConsumivel }}</a>
                </b-table-column>

                <b-table-column field="guia" label="Guia">
                    <router-link :to="{ name: 'guia', params: { id: props.row.idGuia }}">
                        {{ props.row.idGuia }}
                    </router-link>
                </b-table-column>

                <b-table-column field="item" label="Item">
                    {{ props.row.item }}
                </b-table-column>
                
                <b-table-column field="exame" label="Exame">
                    {{ props.row.apelidoExame }}
                </b-table-column>

                <b-table-column field="data" label="Data">
                    {{ $moment(props.row.data).format("DD/MM/YYYY HH:mm") }}
                </b-table-column>

            </template>
        </b-table>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        Voltar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>


<script>
    import titlebar from '@/components/titlebar.vue'
    
    export default {
        data() {
            return {
                lista: [],
                ordenarPor: 'idMovimentacao',
                ordenar: 'desc',
                defaultOrdenar: 'desc',
                loading: false,
                total: null
            }
        },
        components: {
            titlebar        
        },
        computed: {
            titleStack() {
                return [
                    'Relatórios',
                    'Rastreio de Estoque'
                ]
            }
        },
        mounted() {

            this.loading = true;

            this.$http.post('/api/relatorio/EstoqueRastreio', this.$route.query)
                .then(res => res.data)
                .then(data => {
                    this.lista = data;

                    this.loading = false;
                })
                .catch((error) => {
                    this.lista = [];
                    this.loading = false;
                    throw error
                })

        }
    }
</script>