<template>
  <section>
    <article class="panel">
      <div class="panel-heading has-text-centered">
        {{ label }}
      </div>
      <div class="panel-block">
        <b-table :data="interno.items" class="table" striped hoverable>
          <template slot-scope="props" v-if="props.row.show">
            <b-table-column field="" label="">
              <b-button
                type="is-danger"
                native-type="button"
                icon-left="delete"
                class="button-delete"
                @click="deleteItem(props.row)"
              >
              </b-button>
            </b-table-column>
            <b-table-column field="apelido" :label="$t('BUSCAEXAME.APELIDO')">
              {{ props.row.apelido }}
            </b-table-column>
            <b-table-column field="nome" :label="$t('BUSCAEXAME.NOME')">
              <b-autocomplete
                :data="data"
                v-model="props.row.nome"
                ref="autocomplete"
                field="nome"
                icon="magnify"
                :loading="isFetching"
                @typing="getAsyncData"
                @select="selectItem(props.row, $event)"
              >
                <template slot="empty">
                  Nenhum {{ label }} encontrado
                </template>
                <template slot-scope="props">
                  <div>
                    <div class="">
                      <div class="tag">{{ props.option.id }}</div>
                      {{ props.option.apelido }} {{ props.option.nome }}
                    </div>
                    <div><b> - {{$t('BUSCAEXAME.APELIDO')}}:</b> {{ props.option.apelido }}</div>
                    <div><b> - {{$t('BUSCAEXAME.SETOR')}}:</b> {{ props.option.setorNome }}</div>
                    <div>
                      <b> - {{$t('BUSCAEXAME.RECIPENTES')}}:</b> {{ props.option.recipienteId }}
                    </div>
                    <div>
                      <b> - {{$t('BUSCAEXAME.MATERIAL')}}:</b> {{ props.option.materialNome }}
                    </div>
                    <div>
                      <b> - {{$t('BUSCAEXAME.CONSERVANTE')}}:</b> {{ props.option.conservanteNome }}
                    </div>
                  </div>
                  <slot
                    v-if="hasDefaultSlot"
                    :option="props.option"
                    :index="props.index"
                  />
                </template>
              </b-autocomplete>
            </b-table-column>
            <b-table-column field="materialNome" :label="$t('BUSCAEXAME.MATERIAL')">
              {{ props.row.materialNome }}
            </b-table-column>
            <b-table-column field="conservanteNome" :label="$t('BUSCAEXAME.CONSERVANTE')">
              {{ props.row.conservanteNome }}
            </b-table-column>
          </template>
        </b-table>
        <div class="buttons has-text-centered">
          <b-button
            type="is-success"
            native-type="button"
            icon-left="plus"
            class="center"
            @click="addItem()"
          >
            {{$t('BUSCAEXAME.INCLUIR')}}
          </b-button>
        </div>
      </div>
    </article>
  </section>
</template>

<style scoped>
.button-delete {
  max-height: 1.5rem;
}

.table {
  width: 100%;
  display: block;
  /* max-height: 30vh;
  overflow-y: auto; */
}

.panel-block {
  display: block;
}

.center {
  margin: 0 auto;
}
</style>

<script>
import debounce from "lodash/debounce";

export default {
  props: {
    items: [],
    label: String,
  },
  data() {
    return {
      data: [],
      inputid: this.id,
      isFetching: false,
      table: "ExameRecipiente",
      interno: {
        items: [],
      },
    };
  },
  watch: {
    items() {
      this.interno.items = this.items;
    },
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$scopedSlots.default;
    },
  },
  methods: {
    getAsyncData: debounce(function (name) {
      if (!name.length) {
        this.data = [];
        return;
      }
      this.isFetching = true;
      this.$http
        .get(`/Search/${this.table}?texto=${name}&_=${new Date().getTime()}`)
        .then(({ data }) => {
          this.data = [];
          data.forEach((item) => this.data.push(item));
        })
        .catch((error) => {
          this.data = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
    addItem() {
      this.interno.items.push({
        id: null,
        apelido: null,
        changed: true,
        show: true,
      });
      setTimeout(() => {
        this.$refs.autocomplete.focus();
      }, 100);
    },
    deleteItem(item) {
      item.show = false;
      item.id = `${item.id}_0`;
      this.change();
    },
    selectItem(item, option) {
      item.id = option.id;
      item.apelido = option.apelido;
      item.materialNome = option.materialNome;
      item.conservanteNome = option.conservanteNome;
      item.recipienteId = option.recipienteId;
      item.changed = true;
      this.change();
    },
    change() {
      const result = this.interno.items
        .map((i) => {
          const id =
            i.changed && i.id?.toString()?.indexOf("_") < 0
              ? i.id + "_" + i.recipienteId
              : i.id;
          return {
            ...i,
            id: id,
          };
        });
      this.$emit("update:items", result);
    },
  },
};
</script>
