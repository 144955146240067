<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('ESPECIEPAGAMENTOCADASTRO.SALVANDOCADASTRO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="model.nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('ESPECIEPAGAMENTOCADASTRO.NOME')"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <br>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('especie-pagamento-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('ESPECIEPAGAMENTOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('ESPECIEPAGAMENTOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>
<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: null
                },
                salvandoDados: false
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                   this.$t('ESPECIEPAGAMENTOLISTA.MANUTENCAO'),
                    this.$t('ESPECIEPAGAMENTOLISTA.ESPECIEPAG'),
                    this.model.id ?? 'Novo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods:{
            onSubmit() {
                this.salvandoDados = true;
                if(this.model.id == null){
                    this.model.id = 0
                }
                this.$http.post(`/api/manutencao/EspeciePagamento`, this.model )
                    .then(({ data }) => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });       
            }
        },
        created() {            
            if(this.$route.params.id){
                this.$http.get('/api/manutencao/EspeciePagamento?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;
                    })
            }
        }
    }
</script>