<template>
  <div class="modal-card" style="width: 100%;">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ titulo }}</p>
    </header>
    <b-message
      v-for="(erro, index) in erros"
      v-bind:key="index"
      title="Erro"
      type="is-danger"
      aria-close-label="Fechar mensagem"
    >
      {{ erro }}
    </b-message>
    <section class="modal-card-body list  is-marginless">
      <div class="columns is-multiline">
        <searchIdName class="column is-6" :id.sync="pacienteId" @item="escolheuPaciente" v-if="titulo == 'Contas a Receber'" label="Paciente" table="Paciente"></searchIdName>
      </div>
      <div class="panel-block">
        <b-table :data="interno.items" striped hoverable   
          :checked-rows.sync="checkedRows"
          checkable>
          <template slot-scope="props">

            <b-table-column field="documento" label="Documento" v-if="titulo == 'Contas a Pagar'">
              <a @click="abrirPagar(props.row)" class="is-primary"> {{ props.row.documento }}</a>
            </b-table-column>
            <b-table-column field="documento" label="Documento" v-if="titulo == 'Contas a Receber'">
              <a @click="abrirReceber(props.row)" class="is-primary"> {{ props.row.documento }}</a>
            </b-table-column>

            <b-table-column field="paciente" label="Paciente" v-if="titulo == 'Contas a Receber'">
              {{ props.row.pacienteNome }}
            </b-table-column>

            <b-table-column field="fornecedor" label="Fornecedor" v-if="titulo == 'Contas a Pagar'">
              {{ props.row.fornecedor }}
            </b-table-column>

            <b-table-column field="notaFiscal" label="Nota Fiscal" >
              {{ props.row.notaFiscal }}
            </b-table-column>

            <b-table-column field="contaCorrente" label="Conta Corrente">
              {{ props.row.nome }}
            </b-table-column>

            <b-table-column field="dataEmissao" label="Emissão" v-if="titulo == 'Contas a Pagar'">
              {{ props.row.dataPagar |data }}
            </b-table-column>
            <b-table-column field="dataEmissao" label="Emissão" v-if="titulo == 'Contas a Receber'">
              {{ props.row.dataReceber |data }}
            </b-table-column>

            <b-table-column field="dataDeVencimento" label="Vencimento">
              {{ props.row.dataDeVencimento | data}}
            </b-table-column>

            <b-table-column field="observacoes" label="Observação">
              {{ props.row.observacao }}
            </b-table-column>  
                  
            <b-table-column field="valor" label="Valor">
              {{ props.row.valor | currency}}
            </b-table-column>
          </template>
        </b-table>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-pulled-right is-success"
        type="button"
        @click="confirmar()"
      >
        Confirmar
      </button>

      <button
        class="button is-pulled-right"
        type="button"
        @click="sair()"
      >
        Fechar
      </button>
    </footer>
  </div>
</template>

<script>
import moment from 'moment';
import searchIdName from '@/components/searchIdName.vue'

export default {
  props: {
    items: null,
    titulo: null,
  },
  components: {
      searchIdName
  },
  computed: {},
  data(){
      return {
        interno : {
          items: []
        },
        itensCompletos: [],
        erros: [],
        checkedRows: [],
        pacienteId: null
      }
  },
  filters: {
             data: (valor) => {
                if (!valor) return null;
            
                return  moment(valor).format('DD/MM/YYYY');
            },
  },
  watch(){
    /*eslint-disable-next-line*/
    items: () =>{
      this.interno.items = this.items;
    }
  },
  mounted(){
      this.interno.items = this.items;
      this.itensCompletos = this.items;
  },
  methods: {
    changed(row){
      row.changed = true;
      row.temErros = false;
    },
    validate(data){
      this.changed(data);
      return true;
    },     
    abrirReceber(item){
      this.$emit('link', item);
      this.$parent.close();
    },   
    abrirPagar(item){
      this.$emit('link', item);
      this.$parent.close();
    },
    sair() {
        this.$parent.close();
    },
    confirmar(){
        this.$emit('update:rows', this.checkedRows);
        this.$parent.close();
    },          
    getPacientes(text) {
        if(text){
            this.$http.get('/search/Paciente?nome=' + text)
                .then(res => {
                    this.pacientes = res.data.map(x => x.nome);
                });
        }
    },
    escolheuPaciente(val) {
      let id = val.id;

      if(val.data){
        id = val.data.id;
      }

      this.interno.items = this.itensCompletos.filter(x => x.pacienteId == id);
    },    
  },
};
</script>
