var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('titlebar',{attrs:{"title-stack":_vm.titleStack}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"tile is-ancestor"},[_c('div',{staticClass:"tile is-parent is-vertical is-9"},[_c('article',{staticClass:"tile is-child box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"código"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('VALCBHPMCADASTRO.CODIGO'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text","maxlength":"3","has-counter":false},model:{value:(_vm.model.id),callback:function ($$v) {_vm.$set(_vm.model, "id", $$v)},expression:"model.id"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"valor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('VALCBHPMCADASTRO.VAL'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"number","maxlength":"17","has-counter":false,"step":"0.01"},model:{value:(_vm.model.valor),callback:function ($$v) {_vm.$set(_vm.model, "valor", $$v)},expression:"model.valor"}})],1)]}}],null,true)})],1)])])])]),_c('nav',{staticClass:"level"},[_c('div',{staticClass:"level-item"},[_c('p',{staticClass:"buttons"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isInRole('valorescbphm-alterar')),expression:"isInRole('valorescbphm-alterar')"}],attrs:{"type":"is-success","loading":_vm.salvandoDados,"native-type":"submit","icon-left":"check-circle"}},[_vm._v(" "+_vm._s(_vm.$t('VALCBHPMCADASTRO.SALVAR'))+" ")]),_c('b-button',{attrs:{"type":"is-danger","native-type":"button","icon-left":"close-circle"},on:{"click":function($event){return _vm.$router.back();}}},[_vm._v(" "+_vm._s(_vm.$t('VALCBHPMCADASTRO.CANCELAR'))+" ")])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }