<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('RACACADASTRO.SALVANDORACA')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="model.nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('RACACADASTRO.NOME')"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>

                                <div class="column is-2">
                                    <b>{{$t('RACACADASTRO.ESPECIE')}}</b>
                                    <b-select v-model="model.especie.id" expanded >
                                        <option :value="todos">Todos</option>
                                        <template v-for="(item, index) in especiesAtivos">
                                            <option v-if="(model.especie.id == item.id)" selected v-bind:key="index" :value="item.id">
                                                {{item.nome}}
                                            </option>
                                            <option v-else v-bind:key="index" :value="item.id">
                                                {{item.nome}}
                                            </option>
                                        </template>
                                    </b-select>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <br>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('raca-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('RACACADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('RACACADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>
<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: {
                    especie: {
                        id: 0,
                    },
                    id: null,
                    nome: null,
                },
                salvandoDados: false,
                especiesAtivos:  [],
                todos: null
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('RACALISTA.MANUTENCAO'),
                    this.$t('RACALISTA.RACAS'),
                    this.model.id ?? 'Novo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        mounted() {
            this.$http.get('/api/search/especieanimal')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.especiesAtivos = data;
                }
            });
        },
        methods:{
            onSubmit() {
                this.salvandoDados = true;
                if(this.model.id == null){
                    this.model.id = 0
                }

                if(this.model.especie.id == null || this.model.especie.id == 0){
                    this.model.especie = this.todos;
                }

                this.$http.post(`/api/manutencao/raca`, this.model )
                    .then(({ data }) => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            }
        },
        created() {
            if(this.$route.params.id){
                this.$http.get('/api/manutencao/raca?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;
                        if (this.model.especie.id == 0) {
                            this.model.especie = {id: null, nome: null};
                        }
                    })
            }
        }
    }
</script>