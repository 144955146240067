<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('ALERTAEXAME.REPORTAR')}}</p>
        </header>
        <section class="modal-card-body">
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('ALERTAEXAME.EXAMES')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-tag type="is-info" v-for="(exame,index) in selecionado" :key="index">{{ exame.exameApelido }}</b-tag>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('ALERTAEXAME.EMAIL')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-input v-model="email"></b-input>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('ALERTAEXAME.INFO')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-input maxlength="9000" type="textarea" v-model="informacoes"></b-input>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('ALERTAEXAME.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosAlertaExame"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosAlertaExame = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="5"
                              expanded
                              v-model="motivoAlertaExameSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosAlertaExame" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                      :loading="salvandoDados"
                      @click="alertaExame()">{{$t('ALERTAEXAME.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('ALERTAEXAME.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            selecionado: Array
        },
        data() {
            return {
                procurarMotivosAlertaExame: '',
                listaMotivosAlertaExame: [],
                motivoAlertaExameSelecionado: [],
                email: this.guia.convenioEmail,
                informacoes: '',
                salvandoDados: false
            }
        },
        computed: {
            filteredMotivosAlertaExame() {
                return this.procurarMotivosAlertaExame
                    ? this.listaMotivosAlertaExame
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosAlertaExame.toUpperCase()) > -1)
                    : this.listaMotivosAlertaExame
            },
        },
        created() {
            this.$http.get('/api/search/MotivosAlertaExame')
                .then(m => {
                    this.listaMotivosAlertaExame = m.data
                })
            
            this.$http.get(`/api/manutencao/UsuarioSecundariosConvenio?convenioId=${this.guia.convenioId}`)
                .then(m => {

                    let stringEmail = '';

                    if(this.guia.convenioEmail != null && this.guia.convenioEmail != '' && this.guia.convenioEmail != undefined){
                        stringEmail = stringEmail + this.guia.convenioEmail + ';';
                    }
              
                    m.data.forEach((item, index) => {
                        if(item.email != null && item.email != '' && item.email != undefined){ 
                            console.log(item.email);
                            if(item.recebeEmail){
                                stringEmail = stringEmail + item.email + ';';
                            }
                           

                        }
                    });
                
                    this.email = stringEmail
                    
                })                
                                            
        },
        methods: {
            alertaExame() {
                this.salvandoDados = true
                this.$http.post('/api/atendimento/ReportarAoMedico', {
                        id: this.guia.id,
                        item: this.selecionado.map(x => x.item),
                        motivoIds: this.motivoAlertaExameSelecionado,
                        info: this.informacoes,
                        email: this.email
                    })
                    .then((res) => {
                        this.salvandoDados = false
                        if (res.body.erro) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.body.erro,
                                type: 'is-danger'
                            })
                        } else {
                            this.$emit('close')
                        }
                    })
            }
        }
    }
</script>