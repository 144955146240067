<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-loading :active.sync="isLoading"></b-loading>


        <b-collapse class="card has-background-transparent" animation="slide" >
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    Filtro
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>                            
            </div>

            <div class="card-content is-paddingless has-background-light">
                <div class="columns">
                    <div class="column is-3">
                        <periodo label="Período"  :dataInicial.sync="filtro.dataInicial" :dataFinal.sync="filtro.dataFinal"  />
                    </div>

                    <div class="column is-3">
                        <searchIdName class="column is-12" :id.sync="convenioId" table="convenio" label="Convênio"  @item="atualizaFiltroConvenios"/>
                    </div>      

                    <div class="column is-3">
                        <searchIdName class="column is-12" :id.sync="departamentoId" table="departamento" label="Departamento"  @item="atualizaFiltroDepartamentos" />
                    </div>                   

                    <div class="column is-2">
                        
                            <label class="label" style="visibility: hidden; padding-bottom: 5px;">Label Filtro</label>
                            <b-button type="is-primary" native-type="button" icon-left="magnify" @click="pesquisar()">
                                Buscar
                            </b-button>                                                     
                    </div>

                </div>

                <div class="columns">
                    <div class="column is-3">
                        <b-switch v-model="mostrarTat" >
                        Mostrar TAT
                        </b-switch>                            
                    </div>
                </div>


  
            </div>
        </b-collapse>





        <div v-if="lista.length>0" class="tile is-ancestor centralizar">
            <div class="tile is-12 is-centered is-vertical is-parent">
                <div class="tile is-child box">
                    <p class="title">{{subTitulo}}</p>
                    <!-- LINHA CABEÇALHO -->
                    <div class="b-table" >
                        <div class="table-wrapper has-mobile-cards">
                            <table class="table  is-hoverable">
                                <thead>
                                    <th class="width-10">Departamento</th>                                                                                                          
                                    <th class="width-5 label-tooltip">
                                        <b-tooltip label="Exames que foram solicitados por guias" multilined>
                                            Solicitados ?
                                        </b-tooltip>                                        
                                    </th>
                                    <th class="width-5 label-tooltip">                                        
                                        <b-tooltip label="Exames que ja tiveram material recebidos" multilined>
                                            Recebidos ?
                                        </b-tooltip>
                                    </th>
                                    <th class="width-5 label-tooltip">

                                        <b-tooltip label="Exames que tiveram recoletas de material devido algum motivo" multilined>
                                            Recoleta ?
                                        </b-tooltip>
                                                                                
                                    </th>
                                    <th class="width-5 label-tooltip">
                                        <b-tooltip label="Exames que já foram assinados" multilined>
                                            Liberados ?
                                        </b-tooltip>                                         
                                    </th>
                                    <th class="width-5 label-tooltip">                                        
                                        <b-tooltip label="Exames que a data de assinatura foi superior ao prazo de entrega do exame" multilined>
                                        Liberados em Atraso ?
                                        </b-tooltip>                                    
                                    </th>
                                    <th class="width-5 label-tooltip" >
                                        <b-tooltip label="Exames que ainda não foram assinados" multilined>
                                            Em Processo ?
                                        </b-tooltip>                                           
                                    </th>
                                    <th class="width-5 label-tooltip">
                                        <b-tooltip label="Exames que ainda não foram assinados e estão no prazo de entrega" multilined>
                                            Em Processo (Prazo) ?
                                        </b-tooltip>
                                    </th>
                                    <th class="width-5 label-tooltip">

                                        <b-tooltip label="Exames que ainda não foram assinados e estão atrasados com a entrega" multilined>
                                            Em Processo (Atraso) ?
                                        </b-tooltip>

                                    </th>	                                    
                                    <th v-if="mostrarTat" class="width-5 label-tooltip">
                                        <b-tooltip label="Média do tempo total de espera, desde a data do recebimento até a assinatura do exame" multilined>
                                            TAT ?
                                        </b-tooltip>
                                    </th>	                                     
                                    <th v-if="mostrarTat" class="width-5 label-tooltip">
                                        <b-tooltip label="Média do tempo total de espera, dos exames atrasados que ja forma assinados" multilined>
                                            TAT (Atrasado) ?
                                        </b-tooltip>                                        
                                    </th>	                                     
                                    <th v-if="mostrarTat" class="width-5 label-tooltip">
                                        <b-tooltip label="Média do tempo total de espera, dos exames atrasados que ainda não foram assinados" multilined>
                                            TAT (em Atraso) ?
                                        </b-tooltip>                                                                                
                                    </th>	                                     

                                </thead>

                                <tbody>  

                                    <tr v-for="item in lista" :key="item.codigodepartamento">
                                            <td  class="width-10"> {{ item.nomedepartamento }} </td>
                                            <td  class="width-5 button-effect centralizar" @click="listarExames('solicitado',item.codigodepartamento)"> {{ item.solicitado }} </td>                                                 
                                            <td  class="width-5 button-effect centralizar" @click="listarExames('recebido',item.codigodepartamento)"> {{ item.recebido }} </td>                                                 
                                            <td  class="width-5 button-effect centralizar" @click="listarExames('teverecoleta',item.codigodepartamento)"> {{ item.teverecoleta }} </td>                                                 
                                            <td  class="width-5 button-effect centralizar" @click="listarExames('liberado',item.codigodepartamento)"> {{ item.liberado }} </td>  
											<td  :class="item.liberado_atraso>0 ? 'width-5 background-light-red button-effect centralizar' : 'width-5 button-effect centralizar'"  @click="listarExames('liberado_atraso',item.codigodepartamento)" > {{ item.liberado_atraso }} </td>   
                                            <td  class="width-5 button-effect centralizar" @click="listarExames('em_processamento',item.codigodepartamento)"> {{ item.em_processamento }} </td>   
                                            <td  class="width-5 button-effect centralizar" @click="listarExames('em_processamento_prazo',item.codigodepartamento)"> {{ item.em_processamento_prazo }} </td>   
                                            <td  :class="item.em_processamento_atraso>0 ? 'width-5 background-light-red button-effect centralizar' : 'width-5 button-effect centralizar'"  @click="listarExames('em_processamento_atraso',item.codigodepartamento)"> {{ item.em_processamento_atraso }} </td>                                                                                             
                                            <td v-if="mostrarTat"  class="width-5 centralizar"> {{ item.tat_tempo.toFixed(2).replace('.', ',') }} </td> 
                                            <td v-if="mostrarTat" class="width-5 centralizar"> {{ item.tat_atrasado.toFixed(2).replace('.', ',') }} </td> 
                                            <td v-if="mostrarTat"  class="width-5 centralizar"> {{ item.tat_em_atraso.toFixed(2).replace('.', ',') }} </td> 
 
                                    </tr>


                                    <tr class="tr-total">
                                        <td  class="width-10"> TOTAL</td>
                                        <td  class="width-5 centralizar"> {{totais.totalSolicitado}} </td>
                                        <td  class="width-5 centralizar"> {{totais.totalRecebido}} </td>
                                        <td  class="width-5 centralizar"> {{totais.totalTeverecoleta}} </td>
                                        <td  class="width-5 centralizar"> {{totais.totalLiberado}} </td>
                                        <td  class="width-5 centralizar"> {{totais.totalLiberadoAtraso}} </td>
                                        <td  class="width-5 centralizar"> {{totais.totalEmProcessamento}} </td>
                                        <td  class="width-5 centralizar"> {{totais.totalEmProcessamentoPrazo}} </td>
                                        <td  class="width-5 centralizar"> {{totais.totalEmProcessamentoAtraso}} </td>
                                        <td v-if="mostrarTat" class="width-5 centralizar"> {{totais.totalTatTempo.toFixed(2).replace('.', ',')}} </td>
                                        <td v-if="mostrarTat" class="width-5 centralizar"> {{totais.totalTatAtrasado.toFixed(2).replace('.', ',')}} </td>
                                        <td v-if="mostrarTat" class="width-5 centralizar"> {{totais.totalTatEmAtraso.toFixed(2).replace('.', ',')}} </td>
                                    </tr>



                                </tbody>

                            </table>
                        </div>
                    </div>                                        
                </div>



            </div>
        </div>  
        

    </section>
</template>


<style scoped>

    section{
        padding: 0.35rem !important;
    }
    thead{
        font-size: 10px !important;
    }
    .button-effect:hover {
        background-color: #e9efe9 !important;
    }
    .button-effect{
        cursor: pointer !important;
    }
    
    .width-10{
        width: 10% !important;
    }
    .width-5{
        width: 5% !important;
        text-align:  center  !important;
    }      

    .label-tooltip{
        cursor: pointer !important;
    }
    .background-light-red{
        background-color: #ff000036 !important;
    }  

      
</style>

<script>
    import titlebar from '@/components/titlebar.vue'
    import periodo from '@/components/periodo.vue'
    import searchIdName from '@/components/searchIdName.vue'
    
    export default {
        data() {
            return {
                lista: [], 
                isLoading: false,
                subTitulo: '',
                convenioId: null,
                departamentoId: null,
                mostrarTat: false,
                totais:{
                    totalSolicitado: 0.0,
                    totalRecebido:0.0,
                    totalTeverecoleta:0.0,
                    totalLiberado:0.0,
                    totalLiberadoAtraso:0.0,
                    totalEmProcessamento:0.0,
                    totalEmProcessamentoPrazo:0.0,
                    totalEmProcessamentoAtraso:0.0,
                    totalTatTempo:0.0,
                    totalTatAtrasado:0.0,
                    totalTatEmAtraso:0.0
                },

                filtro: {
                   dataInicial: this.$moment().subtract(30, 'days').toDate(),
                   dataFinal: this.$moment().toDate(),
                   convenioId: [],
                   departamentoId: [],
                   tipo: null
                }, 
            }
        },
        components: {
            titlebar,
            periodo,
            searchIdName        
        },
        computed: {
            titleStack() {
                return [
                    'Relatórios',
                    'Acompanhamento de Exames'
                ]
            }
        },
        methods: {

            listarExames(tipo,codigoDepartamento) {
                
                this.filtro.tipo = tipo

                 this.filtro.dataInicial = this.$moment(this.filtro.dataInicial).format();
                 this.filtro.dataFinal = this.$moment(this.filtro.dataFinal).format();
                 
                 
                 this.filtro.departamentoIdLista = [0];
                 this.filtro.departamentoIdLista.push(codigoDepartamento);

                if( this.filtro.convenioId.length<=1){                                        
                    this.filtro.convenioIdLista = null;
                }else{
                    this.filtro.convenioIdLista = this.filtro.convenioId;
                }                 
                                                   
                const routeData =  this.$router.resolve({ name: 'acompanhamentoExamesLista', query: this.filtro });
                window.open(routeData.href, '_blank');                
                                                
            },    

            atualizaFiltroConvenios(dados) {
                this.filtro.convenioId = [0];

                if(dados.data.id>0){
                    this.filtro.convenioId.push(dados.data.id);
                }
                
            },    
            
            atualizaFiltroDepartamentos(dados){
                this.filtro.departamentoId = [0];
                                
                if(dados.data.id>0){
                    this.filtro.departamentoId.push(dados.data.id);
                }
            },

            pesquisar(){

                this.isLoading = true;

                if(document.getElementsByClassName('delete').length>0){
                    document.getElementsByClassName('delete')[0].click();
                }
                 
                this.$http.post('/api/relatorio/AcompanhamentoExames', this.filtro)
                    .then(res => res.data)
                    .then(data => {
                
                        this.lista = data.lista;
                        this.subTitulo = data.subTitulo; 
                        
                    
                    this.lista.forEach(x => {

                        this.totais.totalSolicitado += x.solicitado;
                        this.totais.totalRecebido+= x.recebido;
                        this.totais.totalTeverecoleta+= x.teverecoleta;
                        this.totais.totalLiberado+= x.liberado;
                        this.totais.totalLiberadoAtraso+= x.liberado_atraso;
                        this.totais.totalEmProcessamento+= x.em_processamento;
                        this.totais.totalEmProcessamentoPrazo+= x.em_processamento_prazo;
                        this.totais.totalEmProcessamentoAtraso+= x.em_processamento_atraso;
                        this.totais.totalTatTempo+= x.tat_tempo;
                        this.totais.totalTatAtrasado+= x.tat_atrasado;
                        this.totais.totalTatEmAtraso+= x.tat_em_atraso;     
                       
                    })                         
                        
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.lista = [];
                        this.isLoading = false;
                        throw error
                    })

            }

        }       
    }
</script>