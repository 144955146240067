<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <estudo :estudo="model" :index="0" :selecionado="false" :mostraAccessionNumber="true"></estudo>
        <div class="column is-12">
            <ckeditor v-model="model.report" :config="editorConfig"></ckeditor>     
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                            <!-- v-show="isInRole('estudo-laudar')" -->
                    <b-button type="is-success"
                            :loading="salvandoDados"
                            @click="salvar()"
                            icon-left="check-circle">
                        Salvar
                    </b-button>
                    <b-button type="is-danger"
                            native-type="button"
                            icon-left="close-circle"
                            @click="$router.back();">
                        Cancelar
                    </b-button>
                </p>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <b-field>
                        <b-switch v-model="validado">Validado?</b-switch>
                    </b-field>                
                </div>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import estudo from '@/components/atendimento/estudo.vue'
    import CKEditor from 'ckeditor4-vue'

    export default {
        data() {
            return {
                model: null,
                salvandoDados: false,
                editorConfig: {
                    height: 500,
                    contentsCss: '/home/resultadocss'
                },
                validado: false
            }
        },
        computed: {
            titleStack() {
                return [
                    'Análise',
                    'Estudo',
                    'Laudo'
                ]
            },              
            ...mapGetters([
                'isInRole'
            ])
        },
        components: {
            titlebar,
            estudo,
            'ckeditor': CKEditor.component,
        },
        mounted() {
            this.$http.get(`/api/analitico/estudo?id=${this.$route.params.id}`)
                .then(({ data }) => {
                    this.model = data
                })
                .catch((error) => {
                    this.model = null
                    throw error
                })            
        },
        methods: {
            salvar() {
                this.salvandoDados = true
                this.$http.post('/api/analitico/estudolaudo', { id: this.model.id, report: this.model.report, validado: this.validado })
                .then(() => {
                    this.$router.back()
                })
                .catch(err => {
                    Object.keys(err.body.errors).forEach(x => {
                        this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                    })
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Erro ao salvar laudo',
                        type: 'is-danger',
                        queue: false
                    })
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() => {
                    this.salvandoDados = false
                })
            }
        }
    }
</script>
